import React from 'react'
import Icon from '@mdi/react'
import {mdiClose} from '@mdi/js'

const Modal = ({children, closeModal}) => {

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={closeModal}/>
      {children}
      {/*<div className="modal-content is-flex is-justify-content-center is-align-items-center">*/}
        {/**/}
      {/*</div>*/}
    </div>
  )
}

export default Modal
