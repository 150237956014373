import React from 'react'
import Modal from "../../../components/modals/Modal";
import CloseModalBtn from "../../../components/modals/CloseModalBtn";
import {AuthModalContext} from "../../../Contexts/AuthModalContext";
import {EmailVerificationContext} from "../../../Contexts/EmailVerificationContext";
import {claimListingWithOtp, sendClaimListingOtp, claimListing} from "../../../services/listing";
import {toast} from "react-toastify";
import {AuthenticationContext} from "../../../Contexts/AuthenticationContext";
import {useNavigate} from 'react-router-dom'
import {ConfirmationModalContext} from "../../../Contexts/ConfirmationModalContext";

const ClaimListingModal = ({closeModal, listingId, methodName, contactEmail, authenticatedUser, onListingClaimed, claimableAccountId}) => {

  let navigate = useNavigate()
  let {logout} = React.useContext(AuthenticationContext)
  let {openLoginModal} = React.useContext(AuthModalContext)
  let {openEmailVerificationModal} = React.useContext(EmailVerificationContext)
  let {openConfirmationModal} = React.useContext(ConfirmationModalContext)

  function confirmClaimListing() {
    openConfirmationModal("Are you sure you want to claim the listing for did:" + methodName + '?', handleClaimListing)
  }

  function confirmSendEmail() {
    openConfirmationModal(`Are you sure you want to send an OTP to ${contactEmail} to claim the listing for did:${methodName}`, sendClaimEmail)
  }

  function handleClaimListing() {

    claimListing(listingId)
      .then((res) => {
        toast.success("Listing Succesfully Claimed")
        onListingClaimed()
        closeModal()
      })
      .catch(error => {
        toast.error(error.response.data)
      })
  }

  function sendClaimEmail() {
    sendClaimListingOtp(listingId)
      .then((res) => {
        toast.info("One Time Passcode sent to " + contactEmail)
        openEmailVerificationModal(contactEmail, (otp) => claimListingWithOtp(listingId, otp), () => sendClaimListingOtp(listingId), onListingClaimed)
        closeModal()
      })
      .catch(error => {
        toast.error(error.response.data)
      })

  }

  function getCustomContent() {

    if (!authenticatedUser) {
      return (
        <>
          <p className="pb-3">
            The Contact Email listed in the W3C DID Specification Registries {contactEmail} may be used to claim this
            listing in the DID Directory.
          </p>
          <p className="pb-3">
            To do so, we verify control over the Contact Email by sending a one-time token to the email of record.
          </p>
          <p className="pb-3">
            You may claim this using an existing account, or we can create a new account automatically as part of email
            verification.
          </p>
          {/* If claimableAccountId exists, then claim must be linked to this account */}
          {claimableAccountId ?
            <>
              <div className="pb-3">
              <span className="has-text-primary is-clickable" tabIndex="0" onClick={confirmSendEmail}>
                Send Email
              </span> to claim with the account controlling {contactEmail}.
              </div>
              <div className="pb-3">
              <span className="has-text-primary is-clickable" tabIndex="0" onClick={() => {
                openLoginModal()
              }}>
                Login
              </span> to the account controlling {contactEmail} to claim.
              </div>
            </>
            :
            <>
              <div className="pb-3">
              <span className="has-text-primary is-clickable" tabIndex="0" onClick={() => {
                openLoginModal()
              }}>
                Login
              </span> to claim using an existing account.
              </div>
              <div className="pb-3">
                <span className="has-text-primary is-clickable" tabIndex="0" onClick={confirmSendEmail}>
                Send Email
                </span> to claim with a new account.
              </div>
            </>
          }
        </>
      )
    } else if (!authenticatedUser.auth.verifiedEmails.includes(contactEmail)) {
      if (claimableAccountId) {
        // An account other than the one currently authenticated has verified control over the contactEmail for this listing
        // The current user must authenticate with that account to claim.
        // TODO: Could send email cause account switch?
        return (
          <>
            <p className="pb-3">
              The Contact Email listed in the W3C DID Specification Registries {contactEmail} is controlled by another
              account within the DID Directory.
            </p>
            <p className="pb-3">
              To claim this listing, you must either claim from this existing account or remove the email from this
              account to claim with your current account.
            </p>
            {/*<div className="pb-3">*/}
            {/*<span className="has-text-primary is-clickable" tabIndex="0" onClick={confirmSendEmail}>*/}
            {/*Send Email*/}
            {/*</span> to claim using current account.*/}
            {/*</div>*/}
            <div className="pb-3">
            <span className="has-text-primary is-clickable" tabIndex="0" onClick={() => {
              logout()
              openLoginModal()
            }}>
              Switch Account
            </span> to the account that has verified control over {contactEmail}.
            </div>
          </>
        )
      } else {
        return (
          <>
            <p className="pb-3">
              The Contact Email listed in the W3C DID Specification Registries {contactEmail} may be used to claim this
              listing in the DID Directory.
            </p>
            <p className="pb-3">
              To do so, we verify control over the Contact Email by sending a one-time token to the email of record.
            </p>
            <p className="pb-3">
              You may claim this using your current account, switch to a different account (so you can claim from that
              account), or we can create a new account automatically as part of email verification.
            </p>
            <div className="pb-3">
            <span className="has-text-primary is-clickable" tabIndex="0" onClick={confirmSendEmail}>
              Send Email
            </span> to claim using current account.
            </div>
            <div className="pb-3">
            <span className="has-text-primary is-clickable" tabIndex="0" onClick={() => {
              logout()
              openLoginModal()
            }}>
              Switch Account
            </span> to claim with a different account.
            </div>
          </>
        )
      }

    } else {
      return (
        <>
          <p className="pb-3">
            You are currently logged in
            as {authenticatedUser.profile && authenticatedUser.profile.name ? authenticatedUser.profile.name : authenticatedUser._id} which
            already has verified control over {contactEmail}. You may claim this immediately, without further email
            validation.
          </p>
          <p className="pb-3">
            If you have incorrectly associated {contactEmail} with your account--and you wish to claim this method with
            a different account--you must first remove the verified email, then claim with a different account.
          </p>
          <div className="pb-3">
            <span className="has-text-primary is-clickable" tabIndex="0" onClick={() => {
              confirmClaimListing()
            }}>
              Claim
            </span> method with current account.
          </div>
          <div className="pb-3">
            <span className="has-text-primary is-clickable" tabIndex="0" onClick={() => {
              navigate(`/profile/${authenticatedUser._id}`)
            }}>
              Profile
            </span> to remove the verified email.
          </div>
        </>)
    }
  }


  return <Modal closeModal={closeModal}>
    <div className="modal-content">
      <CloseModalBtn closeModal={closeModal}/>
      <h1 className="title"><span className="has-text-primary">Claim</span> did:{methodName}</h1>
      <div className="pb-3">Contact Email: {contactEmail}</div>
      <p className="pb-3">
        Claiming a method gives control over the DID Directory listing for that method. To ensure the correct party
        controls the method listing, we authenticate method claims using the Contact Email recorded in the W3C DID
        Specification Registries.
      </p>

      {getCustomContent()}
    </div>
  </Modal>
}


export default ClaimListingModal
