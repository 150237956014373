import React from 'react'
import MethodListingProperty from "./MethodListingProperty";

const UnclaimedListingDetails = ({listingData}) => {

  return (
    <div className="is-flex is-flex-direction-column mt-5 is-align-content-space-evenly">
      <MethodListingProperty propertyName="METHOD SPECIFICATION" isUrl={true} listingProperty={listingData.specification}/>
      <MethodListingProperty propertyName="VERIFIABLE DATA REGISTRY" listingProperty={listingData.verifiableDataRegistry}/>
      <MethodListingProperty propertyName="CONTACT NAME" listingProperty={listingData.contactName}/>
      <MethodListingProperty propertyName="CONTACT EMAIL" isEmail={true} listingProperty={listingData.contactEmail}/>
      <MethodListingProperty propertyName="CONTACT WEBSITE" isUrl={true} listingProperty={listingData.contactWebsite}/>



    </div>
  )
}

export default UnclaimedListingDetails
