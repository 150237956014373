import React from 'react'
import ConfirmationModal from "../components/modals/ConfirmationModal";

export const ConfirmationModalContext = React.createContext({})

// TODO: Explore possibility of having a single ModalManager for all modal types?
const ConfirmationModalManager = ({children}) => {
  let [confirmationText, setConfirmationText] = React.useState(null)
  let [onConfirmed, setOnConfirmed] = React.useState(null)
  let [showModal, setShowModal] = React.useState(false)

  function openConfirmationModal(confirmationText, onConfirmed) {
    setConfirmationText(confirmationText)
    setOnConfirmed(() => onConfirmed)
    setShowModal(true)
  }

  function closeModal() {
    setShowModal(false)
    setConfirmationText(null)
    setOnConfirmed(null)
  }

  return (
    <ConfirmationModalContext.Provider value={{openConfirmationModal}}>
      {children}
      {showModal && <ConfirmationModal closeModal={closeModal} confirmationText={confirmationText} onConfirmationSuccess={() => {
        setShowModal(false)
        onConfirmed()
      }}/>}
    </ConfirmationModalContext.Provider>
  )
}

export default ConfirmationModalManager
