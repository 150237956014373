import React from 'react'

const EditableFieldProperty = ({propertyName, value, onValueChange}) => {

  return (
    <div className="mb-3">
      <div className="has-text-black is-size-6 mb-1">{propertyName}</div>
      <input className="input" value={value} onChange={(e) => onValueChange(e.target.value)}/>
    </div>
  )

}

export default EditableFieldProperty
