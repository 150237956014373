import React from 'react';
import {mdiInformationOutline,mdiHelpCircleOutline} from "@mdi/js";
import Icon from '@mdi/react'

const ContactPage = () => {
  return (
    <div  className="container mt-6 pl-6 pr-6">
      <h1 className="title has-text-primary">Contact Us</h1>
      <p className="pb-3 pt-3">
        Got a question, comment, or criticism? We'd love to hear from you.
      </p>
      <div className="card columns p-3">
        <div className="column is-narrow">
          <span className="is-flex is-justify-content-center is-align-items-center icon has-background-white-bis" style={{width: "80px", height: "80px", borderRadius: "100%"}}>
            <Icon path={mdiHelpCircleOutline}
                  title="Technical"
                  size={1}
                  horizontal
                  vertical
                  rotate={180}
                  color="#1A8572"
            />
          </span>
        </div>
        <div className="column">
          <h2 className="has-text-black pb-2 pt-2">TECHNICAL & ADMINISTRATIVE</h2>
          <p>
            For technical and administrative inquiries, email <a href="mailto:admin@diddirectory.com">admin@diddirectory.com</a>.
          </p>
        </div>

      </div>
      <div className="card columns p-3">
        <div className="column is-narrow">
          <span className="is-flex is-justify-content-center is-align-items-center icon has-background-white-bis" style={{width: "80px", height: "80px", borderRadius: "100%"}}>
            <Icon path={mdiInformationOutline}
                  title="Technical"
                  size={1}
                  horizontal
                  vertical
                  rotate={180}
                  color="#1A8572"
            />
          </span>
        </div>
        <div className="column">
          <h2 className="has-text-black pb-2 pt-2">BUSINESS & PRESS</h2>
          <p>
            For business or press inquiries, please contact Legendary Requirements <a href="mailto:diddirectory@legreq.com">diddirectory@legreq.com</a>.
          </p>
        </div>

      </div>



    </div>
  )
}

export default ContactPage
