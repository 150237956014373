import React from "react";
import Modal from "./Modal";
import LoginModalContent from "./LoginModalContent";
import SignUpModalContent from "./SignUpModalContent";

const AuthModal = ({closeModal, showLogin, setShowLogin}) => {



  return (
    <Modal>
      {showLogin ? <LoginModalContent closeModal={closeModal} showSignUp={() => setShowLogin(false)}/> : <SignUpModalContent closeModal={closeModal} showLogin={() => setShowLogin(true)}/>}
    </Modal>
  )
}


export default AuthModal
