import React from 'react'
import Modal from "./Modal";
import {mdiLockOutline} from "@mdi/js";
import Icon from '@mdi/react'
import {toast} from "react-toastify";
import {AuthenticationContext} from "../../Contexts/AuthenticationContext";
import CloseModalBtn from "./CloseModalBtn";
import EditableFieldProperty from "../../pages/MethodListingPage/components/EditableFieldProperty";

// Email to verify is either passed into modal.
// Or entered in the modal through a text input
const EmailVerificationModal = ({email, submitEmailApi, submitOtpApi, closeModal, onOtpSuccess}) => {

  let [sentTime, setSentTime] = React.useState(getTimeString());
  let {refreshAuth} = React.useContext(AuthenticationContext);
  let [otpInput, setOtpInput] = React.useState("");

  let [emailToVerify, setEmailToVerify] = React.useState(email || "");

  let [emailInput, setEmailInput] = React.useState("");


  function getTimeString() {

    function addZero(i) {
      if (i < 10) {i = "0" + i}
      return i;
    }
    let today = new Date();
    let h = addZero(today.getHours());
    let m = addZero(today.getMinutes());
    let s = addZero(today.getSeconds());
    return h + ":" + m + ":" + s;
  }

  function sendOtp() {
    submitEmailApi(emailInput)
      .then(res => {
        setSentTime(getTimeString());
        toast.success("Email Sent");
        setEmailToVerify(emailInput)
      })
      .catch(err => {
        toast.error(err.response.data)
      })
  }


  function resendOtp() {
    submitEmailApi(emailToVerify)
      .then(res => {
        setSentTime(getTimeString());
        toast.success("Email Sent")
      })
      .catch(err => {
        toast.error(err.response.data)
      })
  }


  function submitOtp() {
    // If email not provided, then emailToVerify used. This is found in the listings contactEmail
    if (!email) {
      submitOtpApi(emailToVerify, otpInput)
        .then(res => {
          toast.success("Email Authenticated");
          refreshAuth();
          onOtpSuccess && onOtpSuccess();
          closeModal()
          // onSuccess(res)
        })
        .catch(err => {
          toast.error(err.response.data)
        })
    } else {
      submitOtpApi(otpInput)
        .then(res => {
          toast.success("Email Authenticated");
          refreshAuth();
          onOtpSuccess && onOtpSuccess();
          closeModal()
        })
        .catch(err => {
          toast.error(err.response.data)
        })
    }

  }

  return (
    <Modal>
      <div
        className="modal-content email-verification-modal is-flex is-flex-direction-column is-align-self-center is-justify-content-center">
        <CloseModalBtn closeModal={closeModal}/>
        {/*Either verifying an emailToVerify with an OTP. Or requesting an emailToVerify*/}
        {emailToVerify ?
          <>
            <h1 className="title">Email Verification</h1>
            <div>
              <p className="mb-2">Please enter the verification code sent to:</p>
              <a href={`mailto://${emailToVerify}`}>{emailToVerify}</a>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <input className="input" type="text" placeholder="Verification Code" value={otpInput}
                       onChange={(e) => setOtpInput(e.target.value)}/>
                <span className="icon is-small is-left">
                <Icon path={mdiLockOutline}
                      title="Verification Code"
                      size={1}
                      horizontal
                      vertical
                      rotate={180}
                      color="black"
                />
              </span>
              </p>
              <div className="mt-2">
                <span>Sent by {sentTime}</span>
                <span className="is-pulled-right is-clickable link" onClick={resendOtp}>resend</span></div>
              <div>
              </div>
            </div>
            <div>
            <span className="is-pulled-right">
              <button className="button mr-2" onClick={closeModal}>Cancel</button>
              <button className="button is-primary" onClick={submitOtp}>Confirm</button>
            </span>
            </div>
          </>
          :
          <>
            <h1 className="title">Add Verified Email</h1>
            <div>
              <p className="mb-2">Please enter the email you wish to add to your account:</p>
              <EditableFieldProperty value={emailInput} onValueChange={setEmailInput} propertyName="EMAIL"/>
            </div>
            <div>
            <span className="is-pulled-right">
              <button className="button mr-2" onClick={closeModal}>Cancel</button>
              <button className="button is-primary" onClick={() => {
                sendOtp()
              }}>Confirm</button>
            </span>
            </div>
          </>
        }
      </div>
    </Modal>
  )
}


export default EmailVerificationModal
