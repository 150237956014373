import React from 'react'
import {mdiWeb, mdiEmailOutline} from "@mdi/js";
import Icon from '@mdi/react'

const ContactCard = ({contact}) => {

  return (
    <div className="card m-0" style={{border: "1px solid #DBDBDB"}}>
      <div className={contact.name ? "has-text-grey-dark pb-3" : "has-text-grey-light mb-1"}>
        {contact.name ? contact.name : "No Name Provided"}
      </div>
      <div>
        <span className="icon-text">
        <span className="icon pr-2">
          <Icon path={mdiEmailOutline}
                title="Contact Email"
                size={1}
                horizontal
                vertical
                rotate={180}
                color="#1A8572"
          />

          </span>
            <a href={`mailto://${contact.email}`}>{contact.email}</a>
         </span>
      </div>
      <div>
          <span className="icon-text">
          <span className="icon pr-2">
            <Icon path={mdiWeb}
                  title="Upload Logo"
                  size={1}
                  horizontal
                  vertical
                  rotate={180}
                  color="#1A8572"
            />
          </span>
          <a href={contact.website} target="_blank">{contact.website}</a>
          </span>
      </div>
    </div>
  )

}

export default ContactCard
