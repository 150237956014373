import axiosInstance from './axiosInstance'


export const getUserById = (userId) => {
  return axiosInstance.get("/user/" + userId)
}

export const bindEmail = (email) => {

  return axiosInstance.post("/user/email/add", { email:email})
}

export const bindEmailOtp = (emailAddress, otp) => {

  return axiosInstance.post("/user/email/add/otp", { email: emailAddress, otp:otp},)
}

export const bindGithub = () => {
  return axiosInstance.get("/user/github/add")
}

export const updateProfile = (userId, updateProfileForm) => {
  return axiosInstance.post("/user/profile/update/" + userId, updateProfileForm)
}

export const getListingsForUser = (userId) => {

  return axiosInstance.get("/user/listings/" + userId)

}

export const deleteUserAccount = (userId) => {
  return axiosInstance.delete("/user/delete/" + userId)
}
