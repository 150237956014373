import React, { useRef, useEffect } from "react";
import {AuthenticationContext} from "../../Contexts/AuthenticationContext";
import {Link, NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {AuthModalContext} from "../../Contexts/AuthModalContext";
import { useSearchParams } from "react-router-dom";
import {toast} from 'react-toastify';

const AccountNavItem = () => {
  let [searchParams, setSearchParams] = useSearchParams();


  let {isAuthenticated, userId, logout} = React.useContext(AuthenticationContext)
  let {openSignUpModal, openLoginModal} = React.useContext(AuthModalContext)

  let [isDropdownActive, setIsDropdownActive] = React.useState(false)
  const dropdownRef = useRef(null);

  React.useEffect(() => {
    let registererror = searchParams.get('registererror');
    let loginerror = searchParams.get('loginerror');
    let binderror = searchParams.get('githubbinderror');

    if (registererror) {
      openLoginModal();
      toast.error(registererror);
      setSearchParams({});
    } else if (loginerror) {
      openSignUpModal();
      toast.error(loginerror);
      setSearchParams({});
    } else if (binderror) {
      toast.error(binderror);
      setSearchParams({});
    }

  }, [])

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsDropdownActive(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(dropdownRef);

  return isAuthenticated ? <div ref={dropdownRef} className={isDropdownActive ? "navbar-item has-dropdown is-active" : "navbar-item has-dropdown"}>
      <a className="navbar-link is-arrowless" onClick={(e) => {
        e.preventDefault()
        setIsDropdownActive(true)
      }}>
        <FontAwesomeIcon icon={faUser} size="lg"/>
      </a>
      <div className="navbar-dropdown is-right">
        <NavLink end
                 className={({isActive}) => "navbar-item " + (isActive ? "is-active" : "")}
                 onClick={() => setIsDropdownActive(false)}
                 to={`/profile/${userId}`}>
          Profile
        </NavLink>
        <div className="navbar-item is-clickable is-hoverable" onClick={() => {
          setIsDropdownActive(false)
          logout()
        }}>Logout</div>
      </div>
    </div>
      :
    <div className="navbar-item">
        <button onClick={openSignUpModal} className="button is-primary mr-4">Sign Up</button>
        <button onClick={openLoginModal} className="button">Login</button>
    </div>



}

export default AccountNavItem
