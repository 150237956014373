import React from 'react'

const ColorSelector = ({background, border, onSelectColor}) => {
  return <span
    key={"color_"+background}
    className="is-clickable mr-2 ml-2 mb-1"
    style={{borderRadius: "100%", border: `4px solid ${border}`, width: "40px", height: "40px", backgroundColor: background}}
    onClick={() => {
      onSelectColor(background, border)
    }}
  />
}

export default ColorSelector
