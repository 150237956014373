import React from 'react'
import {authLogout, checkIsAuthenticated} from "../services/auth";

export const AuthenticationContext = React.createContext({})

const AuthenticationManger = ({children}) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  const [userId, setUserId] = React.useState(null)

  React.useEffect(() => {
    refreshAuth()
  }, [])

  const refreshAuth = () => {

    checkIsAuthenticated()
      .then(res => {
        if (res.data.userId) {
          setIsAuthenticated(true);
          setUserId(res.data.userId)
        } else {
          setIsAuthenticated(false);
          setUserId(null)
        }
      })
      .catch(error => {
        setIsAuthenticated(false)
        setUserId(null)
      })
      .finally(() => {
        setIsLoading(false)
      })

  }


  const logout = () => {
    // authLogout()
    authLogout().then(res => {
      setIsAuthenticated(false)
      setUserId(null)
    })

  }

  return (
    <AuthenticationContext.Provider value={{isAuthenticated, userId, isLoading, refreshAuth, logout}}>
      {children}
    </AuthenticationContext.Provider>
  )

}

export default AuthenticationManger
