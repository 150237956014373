import React from 'react';

const Logo = ({isPrimary, style}) => {

  return (
    <a href="/"><span className={isPrimary ? "diddir-logo" : "diddir-logo is-secondary"} style={style}><span>did:</span>directory</span></a>
  )
}

export default Logo
