import React from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import {getListingsByMethodName} from "../services/listing";
import ListingPageContent from "./MethodListingPage/components/ListingPageContent";

const MethodTombstonePage = () => {
  let {methodName} = useParams();

  let [listings, setListings] = React.useState(null)

  React.useEffect(() => {
    getListingsByMethodName(methodName)
      .then(res => {
        setListings(res.data)
      })
  }, [methodName])


  function getPageContent() {
    switch (listings.length) {
      case 0:
        return <div>No Listing Found for Method {methodName}. Would you like to add one?</div>
      case 1:
        return <ListingPageContent listingId={listings[0]._id} listing={listings[0]}/>
      default:
        return <div>There are {listings.length} Listings for Method {methodName}</div>
    }
  }


  return (
    <div>

      {listings && getPageContent()}


    </div>
  )


}

export default MethodTombstonePage
