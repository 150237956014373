import './scss/App.scss';
import React from "react"

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import MethodListingPage from "./pages/MethodListingPage/index";
import AuthenticationManager from "./Contexts/AuthenticationContext";
import ProfilePage from "./pages/ProfilePage";
import MethodTombstonePage from "./pages/MethodTombstonePage";
import AboutPage from "./pages/AboutPage";
import LegalPage from "./pages/LegalPage";
import ContactPage from "./pages/ContactPage";
import EmailVerificationModalManager from "./Contexts/EmailVerificationContext";
import AuthModalManager from "./Contexts/AuthModalContext";
import AppNavBar from "./components/navbar/AppNavBar";
import ConfirmationModalManager from "./Contexts/ConfirmationModalContext";
import W3CModalManager from "./Contexts/W3CModalContext";


function App() {


  return (
    <div className="App pb-5">

      <AuthenticationManager>
        <EmailVerificationModalManager>
          <AuthModalManager>
            <ConfirmationModalManager>
              <W3CModalManager>
                <Router>
                  <AppNavBar/>

                  <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/listing/:id" element={<MethodListingPage/>}/>
                    <Route path="/profile/:profileId" element={<ProfilePage/>}/>
                    <Route path="/about.html" element={<AboutPage/>}/>
                    <Route path="/contact.html" element={<ContactPage/>}/>
                    <Route path="/legal.html" element={<LegalPage/>}/>
                    <Route path="/:methodName" element={<MethodTombstonePage/>}/>
                  </Routes>


                </Router>
              </W3CModalManager>
            </ConfirmationModalManager>
          </AuthModalManager>
        </EmailVerificationModalManager>
      </AuthenticationManager>


      <ToastContainer/>

    </div>
  );
}

export default App;
