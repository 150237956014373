import React from 'react'
import {searchListings} from "../../../services/listing";
import ListingSearchResult from "./ListingSearchResult";
import {Link} from "react-router-dom";
import SearchBar from "./SearchBar";

let debug = require('debug')('SearchListings');

const SearchListings = () => {

  let [didMethods, setDidMethods] = React.useState([])

  let [searchInput, setSearchInput] = React.useState("")

  React.useEffect(() => {
    searchListings()
      .then(res => {
        setDidMethods(res.data)

      })
      .catch(err => {
        debug("Error fetching listings", err)
      })
  }, [])

  function updateSearchInput(e) {
    let query = e.target.value;
    setSearchInput(query);
    searchListings(query)
      .then(res => {
        debug(`${res.data.length} Search Results from query ${query}`);
        setDidMethods(res.data)
    })
  }

  return (
    <div className="is-flex is-flex-direction-column is-align-items-center">
      {/* <span className="has-text-primary mt-2">Public Beta</span> */}
      <SearchBar searchInput={searchInput} updateSearchInput={updateSearchInput} listingsCount={didMethods.length}/>


      <div className="is-flex is-flex-direction-column is-align-items-center" style={{width:"100%", overflowY: "auto", maxHeight: "70vh"}}>
        {didMethods && didMethods.map(method => {
          return <div key={method._id} style={{width: "90%"}}><Link to={`/listing/${method._id}`}>
            <ListingSearchResult w3cRegEntry={method.registryEntry} listingData={method.displayData} listingId={method._id} listingControllerId={method.listingController}/>
          </Link></div>

        })}
      </div>


    </div>
  )
}


export default SearchListings
