import React from 'react'
import {useParams} from 'react-router-dom'
import ListingPageContent from "./components/ListingPageContent";

const MethodListingPage = () => {
  let {id} = useParams();


  return id ? (
    <ListingPageContent listingId={id}/>
  ) : <div/>
}


export default MethodListingPage
