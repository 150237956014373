import React from 'react'
import Logo from "../Logo";
import {NavLink} from "react-router-dom";
import AccountNavItem from "./AccountNavItem";

const AppNavBar = () => {

  let [isActive, setIsActive] = React.useState(false)

  return (
    <div className="navbar is-spaced">
      <div className="navbar-brand">
        <div className="navbar-item">
          <Logo isPrimary={true}/>
        </div>
        <a role="button" className={isActive ? "navbar-burger is-active" : "navbar-burger"} aria-label="menu" aria-expanded="false" onClick={(e) => {
          e.preventDefault()
          setIsActive(!isActive)
        }}>
          <span aria-hidden="true"/>
          <span aria-hidden="true"/>
          <span aria-hidden="true"/>
        </a>
      </div>

      <div className={isActive ? "navbar-menu is-active" : "navbar-menu"} >
        <div className="navbar-start">
          <NavLink end className={({isActive}) => "navbar-item is-tab " + (isActive ? "is-active" : "")} to="/">Listings</NavLink>
          <NavLink end className={({isActive}) => "navbar-item is-tab " + (isActive ? "is-active" : "")} to="/about.html">About</NavLink>
          <NavLink className={({isActive}) => "navbar-item is-tab " + (isActive ? "is-active" : "")} to="/contact.html">Contact</NavLink>
          <NavLink className={({isActive}) => "navbar-item is-tab " + (isActive ? "is-active" : "")} to="/legal.html">Legal</NavLink>
        </div>
        <div className="navbar-end">
          <AccountNavItem/>
        </div>

      </div>

    </div>
  )
}

export default AppNavBar
