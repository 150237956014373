import React from 'react'
import {getUserById} from "../../../services/user";
import {Link} from 'react-router-dom'

const ListingClaimaintTag = ({listingControllerId}) => {

  let [listingController, setListingController] = React.useState(null)

  React.useEffect(() => {
    getUserById(listingControllerId)
      .then(res => {
        setListingController(res.data)
      })
  }, [listingControllerId])



  return (
    <div>
      {listingController && <Link to={`/profile/${listingControllerId}`}>
        {listingController.profile ? (listingController.profile.name ? listingController.profile.name : listingControllerId) : listingControllerId}
      </Link>}

    </div>)



}

export default ListingClaimaintTag
