import React from 'react'
import ListingTag from "./ListingTag";
import {mdiDeleteOutline} from "@mdi/js";
import Icon from '@mdi/react'

const ClaimedListingsCard = ({claimedListings, editMode, listingIdsToRelease, queueListingRelease}) => {


  function getListingItem(listing, index) {
    return <>
      {index !== 0 && <hr key={index + "hrtag"}/>}
      <div key={index + "_tag"}>
        <ListingTag listingData={listing.displayData} listingId={listing._id}/>
        {/*{editMode && <span className="icon is-pulled-right is-clickable" tabIndex="0" onClick={() => {*/}
          {/*queueListingRelease(listing._id)*/}
        {/*}}>*/}
          {/*<Icon path={mdiDeleteOutline}*/}
                {/*title="Remove email"*/}
                {/*size={1}*/}
                {/*horizontal*/}
                {/*vertical*/}
                {/*rotate={180}*/}
                {/*color="black"*/}
          {/*/>*/}
          {/*</span>}*/}
      </div>
    </>
  }

  return <div className="card">
    <h1 className="has-text-black is-size-6 mb-5">CLAIMS</h1>
    {listingIdsToRelease ?
      claimedListings.filter(elem => !listingIdsToRelease.includes(elem._id)).map((listing, index) => {
        return <div key={"listing_div_"+index}>
          {getListingItem(listing, index)}
        </div>
      }) :
      claimedListings.map((listing, index) => {
        return <div key={"listing_div_"+index}>
          {getListingItem(listing, index)}
        </div>
      })
    }

  </div>
}

export default ClaimedListingsCard
