import React from 'react'
import ColorSelector from "./ColorSelector";

let defaultColors = [
  {
    background: '#1A8572',
    border: '#FFFFFF'
  },
  {
    background: '#121212',
    border: '#FFFFFF'
  },
  {
    background: '#FAFAFA',
    border: '#0A0A0A'
  }
]

let customColors = [
  {
    background: '#EA1A0B',
    border: '#FFFFFF'
  },
  {
    background: '#438080',
    border: '#FFFFFF'
  },
  {
    background: '#FF6045',
    border: '#0A0A0A'
  },
  {
    background: '#2EE9E9',
    border: '#0A0A0A'
  },
  {
    background: '#FDC6BB',
    border: '#0A0A0A'
  },
  {
    background: '#D4FBFB',
    border: '#0A0A0A'
  },
  {
    background: '#E12273',
    border: '#FFFFFF'
  },
  {
    background: '#428452',
    border: '#FFFFFF'
  },
  {
    background: '#FE5792',
    border: '#0A0A0A'
  },
  {
    background: '#44AE64',
    border: '#0A0A0A'
  },
  {
    background: '#F9C5D3',
    border: '#0A0A0A'
  },
  {
    background: '#C3FBCD',
    border: '#0A0A0A'
  },
  {
    background: '#C536C1',
    border: '#FFFFFF'
  },
  {
    background: '#428535',
    border: '#FFFFFF'
  },
  {
    background: '#FD31FB',
    border: '#0A0A0A'
  },
  {
    background: '#44B035',
    border: '#0A0A0A'
  },
  {
    background: '#F2C5ED',
    border: '#0A0A0A'
  },
  {
    background: '#B7FCA9',
    border: '#0A0A0A'
  },
  {
    background: '#6A26CF',
    border: '#FFFFFF'
  },
  {
    background: '#548235',
    border: '#FFFFFF'
  },
  {
    background: '#8557E6',
    border: '#0A0A0A'
  },
  {
    background: '#72CD2E',
    border: '#0A0A0A'
  },
  {
    background: '#BAB2DD',
    border: '#0A0A0A'
  },
  {
    background: '#B5FE8A',
    border: '#0A0A0A'
  },
  {
    background: '#0048DF',
    border: '#FFFFFF'
  },
  {
    background: '#797A34',
    border: '#FFFFFF'
  },
  {
    background: '#7995CB',
    border: '#0A0A0A'
  },
  {
    background: '#F2F216',
    border: '#0A0A0A'
  },
  {
    background: '#C2CCDF',
    border: '#0A0A0A'
  },
  {
    background: '#FAFFB1',
    border: '#0A0A0A'
  },
  {
    background: '#1C75DF',
    border: '#FFFFFF'
  },
  {
    background: '#B26221',
    border: '#FFFFFF'
  },
  {
    background: '#5996EB',
    border: '#0A0A0A'
  },
  {
    background: '#FEA15B',
    border: '#0A0A0A'
  },
  {
    background: '#BBCDE7',
    border: '#0A0A0A'
  },
  {
    background: '#FBC9A4',
    border: '#0A0A0A'
  }

]


/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref) {

}


const ColorPicker = ({onSelectColor, currentBackground, isRight}) => {

  const wrapperRef = React.useRef(null)

  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsActive(false)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  let [isActive, setIsActive] = React.useState(false)

  function handleSelectColor(backgroundColor, textColor) {
    setIsActive(false)
    onSelectColor(backgroundColor,textColor)
  }


  return (
    <div className={isActive ? isRight ? "dropdown is-active is-right" : "dropdown is-active" : "dropdown"}>
      <div className="dropdown-trigger">
        <button className="button mt-2" aria-haspopup="true" aria-controls="colorsection-menu" onClick={() => setIsActive(true)}>
          <span className="mr-1" style={{width: "16px", height: "16px", backgroundColor: currentBackground, borderRadius: "100%"}}/>
          Change color
        </button>
      </div>
      <div ref={wrapperRef} className="dropdown-menu" id="colorsection-menu" role="menu">
        <div className="dropdown-content has-background-grey-lighter p-3" style={{width: "368px"}}>
          <div className="p-1">
            <h3 className="mb-3">DEFAULT</h3>
            <div className="is-flex is-flex-wrap-wrap">
              {defaultColors.map(colorSelection => {
                return <ColorSelector key={"colorselector_" + colorSelection.background} background={colorSelection.background} border={colorSelection.border} onSelectColor={handleSelectColor}/>

              })}
            </div>
          </div>
          <div className="p-1">
            <h3 className="mb-3">CUSTOM</h3>
            <div className="is-flex is-flex-wrap-wrap">
              {customColors.map((colorSelection) => {
                return <ColorSelector key={"colorselector_" + colorSelection.background} background={colorSelection.background} border={colorSelection.border} onSelectColor={handleSelectColor}/>
              })}
            </div>
          </div>
        </div>
      </div>

    </div>


  )
}

export default ColorPicker
