import React from 'react'
import {mdiClose} from "@mdi/js";
import Icon from '@mdi/react'

const CloseModalBtn = ({closeModal}) => {
  return (
    <button className="close-modal" onClick={closeModal}>
          <span className="icon">
            <Icon path={mdiClose}
                  title="Close"
                  size={1}
                  horizontal
                  vertical
                  rotate={180}
                  color="black"
            />
          </span>
    </button>
  )
}

export default CloseModalBtn
