import React from 'react'
import {toast} from "react-toastify"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes,faEyeDropper} from "@fortawesome/free-solid-svg-icons";
import { SketchPicker } from 'react-color';
import {EXPRESS_SERVER} from "../env_config";



const ListingLogo = ({logoFileUrl, methodName, backgroundColor, textColor}) => {


  return <div className={logoFileUrl ? "avatar listing logo" : "avatar listing"} style={{backgroundColor: backgroundColor}}>
    {logoFileUrl ? <img alt="DID Method Listing Logo" src={logoFileUrl}/> : <div className="acronym" style={{color: textColor}}>{methodName.substring(0, 3)}</div>}
  </div>
}

export default ListingLogo
