import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync} from '@fortawesome/free-solid-svg-icons'

const MethodListingProperty = ({propertyName, listingProperty, isUrl, isEmail}) => {


  function fetchPropertyComponent() {
    if (!listingProperty) {
      return <span className="is-size-5 has-text-grey-light">None Submitted</span>
    }
    let fieldValue = listingProperty
    let innerComp = null
    if (isEmail) {
      return <a className="is-size-5" href={`mailto:${fieldValue}`} >{fieldValue}</a>
    } else if (isUrl) {
      return <a className="is-size-5" href={fieldValue} target="_blank">{fieldValue}</a>
    } else {
      return <span className="is-size-5 has-text-grey-dark">{fieldValue}</span>
    }
  }

  return (
    <span className="m-4 is-flex is-flex-direction-column">
      <span className="is-size-6 has-text-black">{propertyName}</span>
      {fetchPropertyComponent()}
    </span>
  )
}

export default MethodListingProperty
