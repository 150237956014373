import React from 'react'
import Modal from "./Modal";
import CloseModalBtn from "./CloseModalBtn";
import MethodListingProperty from "../../pages/MethodListingPage/components/MethodListingProperty";

const W3CRegEntryModal = ({regEntryData, closeModal}) => {

  return (
    <Modal closeModal={closeModal}>
      <div
        className="modal-content is-flex is-flex-direction-column is-justify-content-center">
        <div style={{overflow: "scroll"}}>
          <CloseModalBtn closeModal={closeModal}/>
          <h1 className="title has-text-primary ml-4 mb-2">W3C DID Specification Registries</h1>
          <h3 className="has-text-grey-dark ml-4 is-size-4">did:{regEntryData.name}</h3>
          <MethodListingProperty propertyName="METHOD SPECIFICATION" isUrl={true} listingProperty={regEntryData.specification}/>
          <MethodListingProperty propertyName="VERIFIABLE DATA REGISTRY" listingProperty={regEntryData.verifiableDataRegistry}/>
          <MethodListingProperty propertyName="CONTACT NAME" listingProperty={regEntryData.contactName}/>
          <MethodListingProperty propertyName="CONTACT EMAIL" isEmail={true} listingProperty={regEntryData.contactEmail}/>
          <MethodListingProperty propertyName="CONTACT WEBSITE" isUrl={true} listingProperty={regEntryData.contactWebsite}/>
          <div className="is-flex is-align-items-center is-justify-content-center">
            <button className="button p-2 has-background-white-ter" onClick={closeModal}>Close</button>
          </div>
        </div>



      </div>
    </Modal>
  )


}


export default W3CRegEntryModal
