import React from 'react'
import AuthModal from "../components/modals/AuthModal";

export const AuthModalContext = React.createContext({})

const AuthModalManager = ({children}) => {

  let [showAuthModal, setShowAuthModal] = React.useState(false)
  let [showLogin, setShowLogin] = React.useState(false)


  const openLoginModal = () => {
    setShowLogin(true)
    setShowAuthModal(true)
  }

  const openSignUpModal = () => {
    setShowLogin(false)
    setShowAuthModal(true)
  }



  return (
    <AuthModalContext.Provider value={{openSignUpModal, openLoginModal}}>
      {children}
      {showAuthModal && <AuthModal closeModal={() => setShowAuthModal(false)} showLogin={showLogin} setShowLogin={setShowLogin}/>}
    </AuthModalContext.Provider>
  )

}

export default AuthModalManager
