import React from 'react'
import Logo from "../Logo";
import Icon from '@mdi/react'
import {mdiClose, mdiEmailOutline, mdiGithub} from "@mdi/js";
import {EXPRESS_SERVER} from "../../env_config";
import {EmailVerificationContext} from "../../Contexts/EmailVerificationContext";
import {emailOtpAuth, emailRegister} from "../../services/auth";
import {toast} from "react-toastify";
import CloseModalBtn from "./CloseModalBtn";

const SignUpModalContent = ({showLogin, closeModal}) => {
  let {openEmailVerificationModal} = React.useContext(EmailVerificationContext)

  let [emailInput, setEmailInput] = React.useState("")

  function signUpWithEmail() {
    if (!emailInput) {
      toast.error("You must enter an email address");
      return
    }
    emailRegister(emailInput)
      .then(res => {
        setEmailInput("")
        closeModal()
        openEmailVerificationModal(emailInput, (otp) => emailOtpAuth(emailInput, otp), emailRegister)
        toast.success("Email Sent")
      })
      .catch(err => {
        toast.error(err.response.data)
      })
  }

  return (
    <div className="auth-modal columns">
      <CloseModalBtn closeModal={closeModal}/>
      <Logo style={{position: "absolute"}}/>
      <div className="column is-one-third is-background-primary is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-flex-gap">

        <h2 className="subtitle mt-6 has-text-centered">Already registered?</h2>
        <p className="has-text-centered">Sign in with Github or use your email</p>
        <button className="button is-primary" onClick={showLogin}>Sign in</button>

      </div>

      <div className="inner column is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-flex-gap">
        <h1 className="title">Sign up to DID Directory</h1>
        <a className="button pr-1 pl-1" href={`${EXPRESS_SERVER}/api/auth/github/register`}>
          <span className="icon-text">
            <span className="icon">
              <Icon path={mdiGithub}
                    title="Github"
                    size={1}
                    horizontal
                    vertical
                    rotate={180}
                    color="black"
              />
            </span>
            <span>Sign up with Github</span>
          </span>
        </a>
        <span className="email-text">or use your email:</span>
        <div className="field">
          <p className="control has-icons-left">
            <input className="input" type="email" placeholder="Email" value={emailInput}
                   onChange={(e) => setEmailInput(e.target.value)}/>
            <span className="icon is-small is-left">
                <Icon path={mdiEmailOutline}
                      title="Email"
                      size={1}
                      horizontal
                      vertical
                      rotate={180}
                      color="black"
                />
              </span>
          </p>
        </div>
        <button className="button is-primary" onClick={signUpWithEmail}>Sign up</button>


      </div>



    </div>
  )
}

export default SignUpModalContent
