import React from 'react'
import Icon from "@mdi/react";
import {mdiAccount} from "@mdi/js";

const ProfileAvatar = ({profilePicUrl, displayName, userId}) => {

  function getInitialsFromName() {
    let names = displayName.split(" ")
    let initials = ""
    for (let name of names) {
      initials += name.substring(0,1).toUpperCase()
    }
    return initials
  }

  return <div className={profilePicUrl ? "avatar profile logo" : "avatar profile"}>
    {profilePicUrl ?
      <img alt="Profile avatar" src={profilePicUrl}/>
      :

        <>{displayName ?
            <div className="acronym">{getInitialsFromName()}</div>
          :
          <span>
                      <Icon path={mdiAccount}
                            size={4}
                            horizontal
                            vertical
                            rotate={180}
                            color="white"
                      />
            </span>
        }</>
    }
  </div>
}

export default ProfileAvatar
