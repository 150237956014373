import React from 'react'
import {useParams} from 'react-router-dom'
import {AuthenticationContext} from "../../Contexts/AuthenticationContext";
import {getListingsForUser, getUserById} from "../../services/user";
import ProfileDetails from "./components/ProfileDetails";
import EditableProfileDetails from "./components/EditableProfileDetails";
import {toast} from "react-toastify";
import {mdiArrowLeft} from "@mdi/js";
import Icon from '@mdi/react'
import { useNavigate } from "react-router-dom";
const debug = require('debug')('ProfilePage');

const ProfilePage = () => {
  let {profileId} = useParams();
  let navigate = useNavigate();

  let {userId} = React.useContext(AuthenticationContext)
  let [user, setUser] = React.useState(null)

  let [editMode, setEditMode] = React.useState(false)

  let [claimedListings, setClaimedListings] = React.useState(null)

  React.useEffect(() => {
    getListingsForUser(profileId)
      .then(res => {
        debug("listings for user", res);
        setClaimedListings(res.data)
      })
      .catch(err => {
        toast.error(err.response.data)
      })
  }, [profileId])

  const fetchUser = () => {
    getUserById(profileId)
      .then(res => {
        setUser(res.data)

      })
      .catch(err => {
        toast.error(err.response.data)
      })
  }

  React.useEffect(() => {
    fetchUser()
  }, [profileId])

  return <div>
    <div className="has-background-primary p-4" style={{height: "160px", opacity: "90%"}}>
      <span className="is-flex is-clickable is-flex-grow-1" onClick={() => navigate(-1)}>
        <span className="icon is-left">
                <Icon path={mdiArrowLeft}
                      title="Back"
                      size={1}
                      horizontal
                      vertical
                      rotate={180}
                      color="white"
                />
            </span>
        <span className="is-size-6 has-text-white">Back</span>
      </span>
    </div>

    {user && <div className="container" style={{maxWidth: "1000px"}}>
      {editMode ?
        <EditableProfileDetails
          profileId={profileId}
          githubHandle={user.auth.githubHandle}
          endEditSession={() => setEditMode(false)}
          onUpdateSuccessful={(updatedUserData) => setUser(updatedUserData)}
          profileData={user.profile || {}}
          verifiedEmails={user.auth.verifiedEmails}
          githubId={user.auth.githubId}
          refreshData={fetchUser}
          claimedListings={claimedListings}
        /> :
        <ProfileDetails
          profileData={user.profile || {}}
          verifiedEmails={user.auth.verifiedEmails}
          claimedListings={claimedListings}
          userId={user._id}
          isController={userId === profileId}
          setEditMode={setEditMode}
          githubHandle={user.auth.githubHandle}
        />

      }

    </div>}

  </div>


}

export default ProfilePage
