import React from 'react'
import MethodListingProperty from "./MethodListingProperty";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";

import ListingClaimaintTag from "./ListingClaimaintTag";
import ContactCard from "./ContactCard";
import {W3CModalContext} from "../../../Contexts/W3CModalContext";


const ClaimedListingDetails = ({listingData, w3cRegEntry, controllerId}) => {

  let {openW3CModal} = React.useContext(W3CModalContext);

  return (
    <div className="is-flex is-flex-direction-column mt-5 is-align-content-space-evenly">
      <div className="card">
        {listingData.shortDesc && <p className="mb-4 mr-4 ml-4 mt-0 is-size-4">
          {listingData.shortDesc}
        </p>}
        <MethodListingProperty propertyName="METHOD SPECIFICATION" isUrl={true}
                               listingProperty={listingData.specification}/>
        <div className="columns">
          <div className="column is-narrow">
            <MethodListingProperty propertyName="VERIFIABLE DATA REGISTRY"
                                   listingProperty={listingData.verifiableDataRegistry}/>

          </div>
          <div className="column">
            <span className="m-4 is-flex is-flex-direction-column">
              <span className="is-size-6 has-text-black">W3C DID SPECIFICATION REGISTRIES</span>
              <span className="link has-text-primary is-size-5 is-clickable" tabIndex={0} onClick={() => {
                openW3CModal(w3cRegEntry)
              }}>View entry</span>
            </span>
          </div>

        </div>
        <div className="m-4">
          <div className="has-text-black is-size-6">CONTACTS</div>
          <div className="columns mb-2 mt-2">
            {listingData.contacts.map(contact => {
              return <div className="column is-narrow">
                <ContactCard contact={contact}/>
              </div>
            })}

          </div>
        </div>
        <div className="m-4">
          <span className="has-text-black is-size-6">CLAIMAINT</span>
          <ListingClaimaintTag listingControllerId={controllerId}/>
        </div>

      </div>
      <div className="m-4">
        {
          listingData.longDesc && <MDEditor.Markdown source={listingData.longDesc} rehypePlugins={[[rehypeSanitize]]}/>
        }
      </div>


    </div>
  )
}

export default ClaimedListingDetails
