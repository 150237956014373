import React from 'react'
import EditableFieldProperty from "./EditableFieldProperty";
import {mdiDeleteOutline} from "@mdi/js";
import Icon from '@mdi/react'

const EditableContactCard = ({index, contact, updateContact, removeContact}) => {

  return (
    <div className="card" key={`editable-contact-card-${index}`}>
      <div className="has-text-black is-size-6 mb-2">DISPLAY CONTACT {index + 1}
        <span className="icon is-pulled-right is-clickable" tabIndex="0" onClick={() => {
          removeContact(index)
        }
        }>
              <Icon path={mdiDeleteOutline}
                    title="Remove email"
                    size={1}
                    horizontal
                    vertical
                    rotate={180}
                    color="black"
              />
          </span>
      </div>
      <div className="columns">
        <div className="column pl-0">
          <EditableFieldProperty propertyName="NAME" value={contact.name} onValueChange={(value) => updateContact(index, "name", value)}/>
        </div>
        <div className="column">
          <EditableFieldProperty propertyName="EMAIL" value={contact.email} onValueChange={(value) => updateContact(index, "email", value)}/>
        </div>
        <div className="column">
          <EditableFieldProperty propertyName="WEBSITE" value={contact.website} onValueChange={(value) => updateContact(index, "website", value)}/>
        </div>
      </div>

    </div>
  )
}

export default EditableContactCard
