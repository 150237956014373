import React from 'react'
import Icon from '@mdi/react'
import {mdiClose, mdiEmailOutline, mdiGithub} from '@mdi/js'
import Logo from "../Logo";
import {EXPRESS_SERVER} from "../../env_config";
import {EmailVerificationContext} from "../../Contexts/EmailVerificationContext";
import {toast} from "react-toastify";
import {emailLogin, emailOtpAuth} from "../../services/auth";
import CloseModalBtn from "./CloseModalBtn";

const LoginModalContent = ({showSignUp, closeModal}) => {
  let {openEmailVerificationModal} = React.useContext(EmailVerificationContext)

  let [emailInput, setEmailInput] = React.useState("")

  function loginWithEmail() {
    if (!emailInput) {
      toast.error("You must enter an email address")
      return
    }
    emailLogin(emailInput)
      .then(res => {
        setEmailInput("")
        closeModal()
        openEmailVerificationModal(emailInput, (otp) => emailOtpAuth(emailInput,otp), emailLogin)
        toast.success("Email Sent")
      })
      .catch(err => {
        toast.error(err.response.data)
      })

  }

  return (
    <div className="auth-modal columns">
      <CloseModalBtn closeModal={closeModal}/>
      <Logo isPrimary={true} style={{position: "absolute"}}/>
      <div className="column is-two-thirds is-flex is-flex-direction-column is-justify-content-center">

        <div className="inner">
          <h1 className="title mt-6">Log in to DID Directory</h1>
          <a className="button pr-1 pl-1" href={`${EXPRESS_SERVER}/api/auth/github/login`}>
              <span className="icon-text">
                <span className="icon">
                  <Icon path={mdiGithub}
                        title="Github"
                        size={1}
                        horizontal
                        vertical
                        rotate={180}
                        color="black"
                  />
                </span>
                <span>Sign in with Github</span>
              </span>
          </a>
          <div className="email-text">or use your email:</div>


          <div className="field">
            <p className="control has-icons-left">
              <input className="input" type="email" placeholder="Email" value={emailInput}
                     onChange={(e) => setEmailInput(e.target.value)}/>
              <span className="icon is-small is-left">
                <Icon path={mdiEmailOutline}
                      title="Github"
                      size={1}
                      horizontal
                      vertical
                      rotate={180}
                      color="black"
                />
              </span>
            </p>
          </div>
          <button className="button is-primary" onClick={loginWithEmail}>Log in</button>
        </div>
      </div>
      <div
        className="column is-one-third is-background-primary is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-flex-gap">
        <h2 className="subtitle">Are you new here?</h2>
        <p className="has-text-centered">Create an account with Github or email</p>
        <button className="button is-primary" onClick={showSignUp}>Sign up</button>

      </div>
    </div>
  )
}

export default LoginModalContent
