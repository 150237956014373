import React from 'react'
import ListingLogo from "../../../components/ListingLogo";
import {EXPRESS_SERVER} from "../../../env_config";
import {W3CModalContext} from "../../../Contexts/W3CModalContext";

const ListingSearchResult = ({listingData, listingId, w3cRegEntry,listingControllerId}) => {

  let {openW3CModal} = React.useContext(W3CModalContext)

  let [logoFileUrl, setLogoFileUrl] = React.useState(null)

  React.useEffect(() => {

    setLogoFileUrl(listingData.logoFileName ? `${EXPRESS_SERVER}/listingLogos/${listingId}/${listingData.logoFileName}` : null)
  }, [listingData])

  return (
    <div key={listingId} className="card listing-search-result has-background-white columns is-3">
      <div className="column is-narrow">
        <ListingLogo
          logoFileUrl={logoFileUrl}
          methodName={listingData.name}
          backgroundColor={listingData.backgroundColor || "#1A8572"}
          textColor={listingData.textColor || "#FFFFFF"}
        />
      </div>
      <div className="column is-flex is-flex-direction-column">
        <div>
          <span className="pr-2 pb-2 has-text-black-bis has-text-weight-bold is-size-3">
            did:{listingData.name}
          </span>
          <button className="link has-text-grey-light is-size-4 pb-2" tabIndex={-1} onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            openW3CModal(w3cRegEntry)
          }}>#</button>
          {!listingControllerId && <span className="is-pulled-right pr-1 pl-1 has-text-black-bis is-size-6 has-background-white-ter">Unclaimed</span>}
        </div>
        {listingData.shortDesc && <div className="has-text-grey-dark is-size-4">{listingData.shortDesc}</div>}
        <hr/>
        <div className="columns is-3">
          <div className="column is-full-mobile">
            <div className="has-text-black-bis is-size-6">VERIFIABLE DATA REGISTRY</div>
            <div className="has-text-grey-dark is-size-4">{listingData.verifiableDataRegistry}</div>
          </div>
          <div className="column">
            <div className="has-text-black-bis is-size-6">METHOD SPECIFICATION</div>
            <button className="link is-size-4" tabIndex={-1} onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              window.open(listingData.specification, '_blank').focus();
            }} href={listingData.specification}>{listingData.specification}</button>
          </div>
        </div>
      </div>
    </div>
  )
}


export default ListingSearchResult
