import React from 'react';
import Modal from "./Modal";

const ConfirmationModal = ({closeModal, confirmationText, onConfirmationSuccess}) => {

  return (
    <Modal closeModal={closeModal}>
      <div className="modal-content">
        <div className="has-text-black is-size-4">{confirmationText}</div>
        <span className="is-pulled-right mt-3">
          <button className="button m-2" onClick={closeModal}>No</button>
          <button className="button is-primary m-2" onClick={onConfirmationSuccess}>Yes</button>
        </span>

      </div>
    </Modal>
  )
}


export default ConfirmationModal
