import React from 'react'
import Modal from "../../../components/modals/Modal";
import CloseModalBtn from "../../../components/modals/CloseModalBtn";

const UnclaimableModal = ({closeModal, methodName}) => {

  return <Modal closeModal={closeModal}>
    <div className="modal-content">
      <CloseModalBtn closeModal={closeModal}/>
      <h1 className="title">Unclaimable did:{methodName}</h1>
      <p className="pb-3">Claiming a method gives control over the DID Directory listing for that method.
        To ensure the correct party controls the method listing, we authenticate method claims using the Contact Email recorded in the W3C DID Specification Registries.</p>
      <p className="pb-3">
        This method is currently unclaimable because the method listing in the <a href="https://w3c.github.io/did-spec-registries/#did-methods" target="_blank">W3C DID Specification Registries</a> lacks a Contact Email.
      </p>
      <p className="pb-3">
        To claim this method, FIRST update the <a href={`https://github.com/w3c/did-spec-registries/blob/main/methods/${methodName}.json`} target="_blank">did:{methodName} entry</a> by submitting a pull request. For best results, add a single email address for Contact Email in the JSON file.
      </p>
      <p className="pb-3">
        SECOND, wait for the DID Directory to import updates from the DID Specification Registries. Currently we update once per day.
      </p>
      <p className="pb-5">
        THIRD, return to the DID Directory and claim the listing.
      </p>
      <button className="button is-primary is-pulled-right" onClick={() => closeModal()}>Acknowledge</button>
    </div>
  </Modal>
}

export default UnclaimableModal
