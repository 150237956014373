import React from 'react'
import {mdiDeleteOutline, mdiEmailOutline, mdiGithub} from "@mdi/js";
import {bindEmail, bindEmailOtp} from "../../../services/user";
import {EXPRESS_SERVER} from "../../../env_config";
import {EmailVerificationContext} from "../../../Contexts/EmailVerificationContext";
import Icon from '@mdi/react'

const AuthMechanismsCard = ({emails, setEmails, refreshData, githubBound, githubId, githubHandle, setGithubBound}) => {
  let {openEmailVerificationModal} = React.useContext(EmailVerificationContext)


  return (
    <div className="card">
      <div className="is-size-6 has-text-black pb-4">VERIFIED AUTHENTICATION MECHANISMS</div>
      <hr/>
      {emails.map((email, index) => {
        return <div key={"authmech_"+index}>
          <span className="icon-text">
            <span className="icon">
              <Icon path={mdiEmailOutline}
                    title="Github"
                    size={1}
                    horizontal
                    vertical
                    rotate={180}
                    color="black"
              />
            </span>
            <span className="is-size-6 has-text-black">{email}</span>
          </span>
          <span className="icon is-pulled-right is-clickable" tabIndex="0" onClick={() => {
            setEmails(emails.filter((_, i) => i !== index))
          }
          }>
            <Icon path={mdiDeleteOutline}
                  title="Remove email"
                  size={1}
                  horizontal
                  vertical
                  rotate={180}
                  color="black"
            />
          </span>
          <hr/>
        </div>
      })}
      {githubBound && <div>
        <span className="icon-text">
            <span className="icon">
              <Icon path={mdiGithub}
                    title="Github"
                    size={1}
                    horizontal
                    vertical
                    rotate={180}
                    color="black"
              />
            </span>
            <span className="is-size-6 has-text-black">{githubHandle}</span>
          </span>

        <span className="icon is-pulled-right is-clickable" tabIndex="0" onClick={() => {
          setGithubBound(false)
        }
        }>
              <Icon path={mdiDeleteOutline}
                    title="Remove email"
                    size={1}
                    horizontal
                    vertical
                    rotate={180}
                    color="black"
              />
          </span>
        <hr/>
      </div>}
      <button className="button has-background-white-bis mr-3" onClick={() => openEmailVerificationModal(null, bindEmailOtp, bindEmail, refreshData)}>
        <span className="icon-text">
            <span className="icon">
              <Icon path={mdiEmailOutline}
                    title="Github"
                    size={1}
                    horizontal
                    vertical
                    rotate={180}
                    color="black"
              />
            </span>
            <span>Add Email</span>
          </span>
      </button>
      {!githubId && <a className="button has-background-white-bis" href={`${EXPRESS_SERVER}/api/user/github/add`}>
          <span className="icon-text">
            <span className="icon">
              <Icon path={mdiGithub}
                    title="Github"
                    size={1}
                    horizontal
                    vertical
                    rotate={180}
                    color="black"
              />
            </span>
            <span>Bind Github</span>
          </span>
      </a>}

    </div>
  )

}

export default AuthMechanismsCard
