import React from 'react'
import { mdiMagnify} from "@mdi/js";
import Icon from '@mdi/react'

const SearchBar = ({searchInput, updateSearchInput, listingsCount}) => {

  return (
    <div className="mt-6 mb-4 field has-addons" style={{width: "60%", maxWidth: "660px"}}>
      <div className="control has-icons-left has-icons-right" style={{width: "100%"}}>

        <input className="input" type="text" aria-label="Search ..." aria-required={true} value={searchInput}
               onChange={updateSearchInput} placeholder="Search ..."/>
                <div className="has-text-right">

        {listingsCount} listings
      </div>

        <span className="icon is-left">
            <Icon path={mdiMagnify}
                  title="Search"
                  size={1}
                  horizontal
                  vertical
                  rotate={180}
                  color="#4A4A4A"
            />
      </span>

      </div>

    </div>


  )
}

export default SearchBar
