import React from 'react'
import EditableFieldProperty from "./EditableFieldProperty";
import rehypeSanitize from "rehype-sanitize";
import MDEditor from "@uiw/react-md-editor";
import {toast} from "react-toastify"
import {updateListingDisplayData, unclaimListing} from "../../../services/listing";
import {ConfirmationModalContext} from "../../../Contexts/ConfirmationModalContext";
import EditableContactCard from "./EditableContactCard";

const debug = require('debug')('EditableListingDetails');


const EditableListingDetails = ({listingId, listingData, logoFile, backgroundColor, textColor, closeEditSession, onListingUpdated, uploadedLogo, deleteLogoFile}) => {

  let {openConfirmationModal} = React.useContext(ConfirmationModalContext);


  let [vdrValue, setVdrValue] = React.useState(listingData.verifiableDataRegistry || "");
  let [specValue, setSpecValue] = React.useState(listingData.specification || "");
  let [shortDesc, setShortDesc] = React.useState(listingData.shortDesc || "");

  let [contactsList, setContactsList] = React.useState(listingData.contacts || []);

  let [longDesc, setLongDesc] = React.useState(listingData.longDesc || "");


  function submitUpdatedMethodListingDetails(event) {
    event.preventDefault();

    let updateListingForm = new FormData()
    let updatedListingDisplayData = {
      verifiableDataRegistry: vdrValue,
      specification: specValue,
      contacts: contactsList,
      shortDesc: shortDesc,
      longDesc: longDesc,
      backgroundColor: backgroundColor,
      textColor: textColor,
      deleteLogoFile
    };

    debug("Submitting Updated Method Listing", updatedListingDisplayData);



    updateListingForm.append("listingUpdateData", JSON.stringify(updatedListingDisplayData));
    updateListingForm.append("logoFile", uploadedLogo);

    updateListingDisplayData(listingId, updateListingForm)
      .then(res => {
        closeEditSession()
        onListingUpdated(res.data)
        toast.success("Succesfully updated listing")
      }).catch(err => {
        toast.error(err.response.data)
    })


  }

  function releaseListingClaim() {
    unclaimListing(listingId)
      .then(res => {
        closeEditSession()
        onListingUpdated(res.data)

        toast.success("Listing claim released")
      })
      .catch(err => {
        toast.error(err.response.data)
      })
  }

  function updateContact(index, field, value) {
    let newContactList = [...contactsList];
    newContactList[index][field] = value;
    setContactsList(newContactList)
  }

  function removeContact(removeIndex) {
    let newContactList = contactsList.filter((_, index) => index !== removeIndex);
    setContactsList(newContactList)
  }

  return (
    <div>
      <div className="card">
        <div className="has-text-black is-size-6 mb-2">SHORT DESCRIPTION</div>
        <textarea className="input" value={shortDesc} onChange={(e) => setShortDesc(e.target.value)} placeholder="Add in an introdutory description of the method..." style={{width: "100%", height: "120px"}}/>
      </div>

      <div className="card">
        <EditableFieldProperty propertyName="METHOD SPECIFICATION LINK" value={specValue} onValueChange={setSpecValue}/>
        <EditableFieldProperty propertyName="VERIFIABLE DATA REGISTRY" value={vdrValue} onValueChange={setVdrValue}/>
      </div>

      {contactsList.map((contact, index) => <EditableContactCard contact={contact} index={index} updateContact={updateContact} removeContact={removeContact}/>)}

      <div className="is-flex is-align-content-center is-justify-content-center">
        <button className="button" onClick={() => {
          setContactsList([...contactsList, {name: "", email: "", website: ""}])
        }}><strong>Add another contact</strong></button>
      </div>

      <div className="card">
        <div className="has-text-black is-size-6 mb-2">LONG DESCRIPTION</div>
        <MDEditor

          value={longDesc}
          onChange={setLongDesc}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
          }}

        />
      </div>
      <div>
        <button className="button is-danger" onClick={() => {
          openConfirmationModal(`Are you sure you want to delete your claim of the listing for did:${listingData.name}?`, releaseListingClaim)
        }}>Release Claim</button>
        <div className="is-pulled-right">
          <button className="button mr-3" onClick={closeEditSession}>Cancel</button>
          <button className="button is-primary pr-5 pl-5" onClick={submitUpdatedMethodListingDetails}>Save Changes</button>
        </div>
      </div>


    </div>


  )
}

export default EditableListingDetails
