import React from "react"

const LegalPage = () => {

  return (
    <div className="container mt-6 pl-6 pr-6">
      <h1 className="title has-text-primary">DID Directory - Privacy Notice</h1>
      <h2 className="subtitle">Last Updated/Effective Date: July 10th, 2023</h2>
      <p className="pb-3 pt-3">
        Legendary Requirements (“<b>Legendary Requirements Inc.</b>,” “<b>we</b>,” “<b>us</b>,” or “<b>our</b>”) respect your privacy. This Privacy Notice describes how we process Personal Information (defined below) that we collect via the sites, applications, social media pages, or other platforms that link to it (collectively, the “Site”), or that is otherwise provided to, or collected by, us in connection with the services we offer (our “Services”).
      </p>
      <p className="pb-5">
        We may modify this Privacy Notice at any time. All changes will be effective immediately upon posting to the Site. Material changes will be conspicuously posted on the Site or otherwise communicated to you.

      </p>

      <h2 className="is-capitalized has-text-black pb-2 is-size-4 has-text-weight-bold">Personal Information We Collect</h2>
      <p className="content">
        We may collect a range of Personal Information. “Personal Information” means information that uniquely identifies, relates to, describes, or is reasonably capable of being associated with or linked to you.
      </p>
      <p className="content pb-5">
        The types of Personal Information we collect may include:
        <ul>
          <li><b>Contact Information</b> - If you submit an inquiry, create a membership account, or provide information on our Site, we may collect your contact information. This may include your name, email address, demographic information, educational records, and phone number.  </li>
          <li><b>Financial Information</b> - If you make any donations or purchases through our Site, we will collect your financial information, including your name and payment information.</li>
          <li><b>Location Data</b> - While navigating our Site, your mobile device or browser may share your location data, both through WiFi and GPS. </li>
          <li><b>Usage Information</b> - When you use our Site, our servers may automatically record information, including your IP Address, browser type, referring URLs (e.g., the site you visited before coming to our Site), domain names associated with your internet service provider, and information on your interaction with the Site </li>
          <li><b>Communication Information</b> - We may collect audio, electronic, or visual information, which includes screen sharing views, chat communications, data in any files uploaded, emailed or otherwise provided by you, the contents of your communications with us (for example, when sent via e-mail, social media, telephone or otherwise), and inferences we may make from other Personal Information we collect.</li>
        </ul>
      </p>
      <h2 className="has-text-black pb-2 is-size-4 has-text-weight-bold">How We Collect Your Personal Information</h2>
      <p className="content pb-5">
        <ul>
          <li><b>We May Collect Your Personal Information Directly From You</b> - For example, if you create an account on the Site, or request information regarding our Services.
          </li>
          <li><b>We May Also Collect Personal Information From Third Parties</b> - This includes personal information which may be published by the W3C DID Specification Registry at <a href="https://www.w3.org/TR/did-spec-registries/" target="_blank">https://www.w3.org/TR/did-spec-registries/</a>.
          </li>
          <li><b>Through Online Tracking Technologies</b> - We may use cookies, device identifiers, and similar technologies such as pixels, web beacons, and local storage to collect usage and browser information about how you use the Site.  We process Personal Information collected through such technologies to help operate certain features of the Site, to enhance your experience through personalization, and to help us better understand the features of the Site that you and other users are most interested in.
          </li>
          <li>We utilize the following types of cookies:
            <ul>
              <li><b>Strictly Necessary Cookies</b> - These cookies are fundamental to the functionality of the Site. These cookies are essential.</li>
              <li><b>Performance Cookies</b> - This type of cookie collects information on how people use the Site, to enable us to improve how the site operates. For example, Google Analytics cookies help us understand how customers arrive at the Site, browse or use the Site and highlight areas where we can improve areas such as navigation, shopping experience and marketing campaigns.
              </li>
              <li><b>Functionality Cookies
              </b> - These cookies may remember the operations such as your preference so that we can provide you with better services and improve our operation models.</li>
            </ul>

            <p>Most browsers provide you with the ability to block, delete, or disable cookies, and your mobile device may allow you to disable transmission of unique identifiers and location data. If you choose to reject cookies or block device identifiers, some features of the Site may not be available, or some functionality may be limited or unavailable.  Please review the help pages of your browser or mobile device for assistance with changing your settings.</p>

            <p>We do not respond to Do Not Track (“DNT”) signals sent to us by your browser at this time. To learn more about how DNT works, please visit <a href="https://allaboutdnt.com/" target="_blank">https://allaboutdnt.com/</a>.</p>
          </li>
          <li><b>Through Analytics</b> - We use self-hosted analytics services to assist us with analyzing our website traffic through cookies and similar technologies. </li>
        </ul>
      </p>

      <h2 className="has-text-black pb-2 is-size-4 has-text-weight-bold">How We Use Personal Information</h2>
      <p className="content pb-5">
        To the extent permitted by applicable law, we may use Personal Information to:
        <ul>
          <li>operate the Site and provide support to our business functions, and provide our Services;</li>
          <li>create an account;</li>
          <li>protect against criminal activity, claims and other liabilities;</li>
          <li>send you information about our Services;</li>
          <li>respond to reviews, comments, or other feedback provided to us;</li>
          <li>support and personalize the Site and our advertising efforts;</li>
          <li>protect the security and integrity of the Site;</li>
          <li>provide user support;</li>
          <li>undertake benchmarking, data analysis, audits, developing new products, enhancing the Site, facilitating product, software and applications development, improving the Site or our services, conducting research, analysis, studies or surveys, identifying usage trends, as well as for other analytics purposes;</li>
          <li>comply with applicable legal or regulatory requirements and our policies;</li>
          <li>undertake research projects to evaluate effectiveness of our Services;</li>
          <li>communicate with you via text message or other electronic communication.</li>
        </ul>
      </p>
      <h2 className="has-text-black pb-2 is-size-4 has-text-weight-bold">Sharing of Personal Information</h2>
      <p className="content pb-5">
        We may share your Personal Information in the following circumstances:
        <ul>
          <li><b>For Legal Obligation or Safety Reasons</b> - When we have a good faith belief that access, use, preservation or disclosure of Personal Information is reasonably necessary to (a) satisfy or comply with any requirement of law, regulation, legal process, or enforceable governmental request, (b) enforce or investigate a potential violation of the Terms of Use, (c) detect, prevent, or otherwise respond to fraud, security or technical concerns, or (d) support auditing and compliance functions.</li>
          <li><b>In the Case of a Merger or Sale</b> - If we are involved in a merger, acquisition, or any form of transfer or sale of some or all of its business, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding. Personal Information may be transferred along with the business. Where legally required we will give you prior notice and if you have a legal right to do so, an opportunity to object to this transfer.</li>
          <li><b>To Service Providers</b> - When we hire a service provider to help operate the Site or our business, we may give access to Personal Information as necessary to perform services on our behalf. This may include service providers that operate our Site, send our communications, or run our promotions. </li>
        </ul>
      </p>
      <h2 className="has-text-black pb-2 is-size-4 has-text-weight-bold">How Long Do We Keep Your Personal Information</h2>
      <p className="content pb-5">
        We will retain your Personal Information for as long as necessary to fulfill the purposes for which it has been collected, as outlined in this Privacy Notice, or any longer retention period required by law.
      </p>
      <h2 className="has-text-black pb-2 is-size-4 has-text-weight-bold">How We Protect Your Personal Information</h2>
      <p className="content pb-5">
        We use our commercially reasonable efforts to protect the confidentiality and security of Personal Information we process. However, despite these efforts to store Personal Information in a secure operating environment, we cannot guarantee the security of Personal Information during its transmission or its storage on our systems. Further, while we attempt to ensure the integrity and security of Personal Information, we cannot guarantee that our security measures will prevent third parties such as hackers from illegally obtaining access to Personal Information.
      </p>
      <h2 className="has-text-black pb-2 is-size-4 has-text-weight-bold">Your Choices and Controls</h2>
      <p className="pb-3">
        Depending on where you live, you may have the right to exercise certain rights and choices regarding our collection, use, and sharing of your Personal Information. To exercise your rights, or to opt-out of marketing or electronic communications, please email us at <a href="mailto://privacy@diddirectory.com">privacy@diddirectory.com</a> and follow the instructions included in the email or text correspondence.
      </p>
      <p className="content pb-5">
        Please note that, even if you unsubscribe from certain correspondence, we may still need to contact you with important transactional or administrative information, as permitted by law.  Additionally, if you withdraw your consent or object to processing, or if you choose not to provide certain Personal Information, we may be unable to provide some or all of our services to you.
      </p>
      <h2 className="has-text-black pb-2 is-size-4 has-text-weight-bold">International Use of the Site</h2>
      <p className="content pb-5">This Site is hosted in the United States. If you are visiting this Site from outside of the United States, please note that your Personal Information is being transferred to, stored, collected, or processed in the United States.
      </p>
      <h2 className="has-text-black pb-2 is-size-4 has-text-weight-bold">Children’s Privacy</h2>
      <p className="content pb-5">
        The Site and our Services are not intended for people under the age of 16.  We do not knowingly collect Personal Information from or about any person under the age of 16.  If you are under 16 years old and wish to ask a question or use this Site or our Services in any way that requires you to submit your Personal Information, please have your parent or guardian do so on your behalf.
      </p>
      <h2 className="has-text-black pb-2 is-size-4 has-text-weight-bold">Individuals in the European Union and United Kingdom</h2>
      <p className="content pb-5">
        The purpose of this section is to provide additional information regarding our processing of Personal Information of people located in the European Union (“EU”) and/or United Kingdom (“UK”).  THIS SECTION ONLY APPLIES TO YOU IF YOU ARE LOCATED IN THE EU or UK.
        <ul>
          <li><b>Legal Basis for Processing</b>
            <p>Our legal basis for processing the Personal Information described in this Privacy Notice will depend on the Personal Information concerned and the context in which we process it.  We process Personal Information from you:
              <ul>

                <li>where we need it to perform a contract with you;</li>
                <li>where the processing is in our legitimate interests (including the purposes described, above, in How We Use Personal Information);</li>
                <li>where the processing is necessary for us to meet our applicable legal obligations; or</li>
                <li>if we otherwise have your consent.</li>
              </ul>
            </p>
          </li>
          <li>
            <b>Special Category Personal Data</b>
            <p>
              “Special Category Data” is any data that reveals your racial or ethnic origin, political opinions, religious, moral or philosophical beliefs, trade union membership, political views, the processing of genetic data, biometric data for the purpose of identifying a person, and data concerning health or a person’s sex life and/or sexual orientation.  We do not knowingly process Special Category Data.
            </p>
          </li>
          <li>
            <b>Transfers to Third Parties and Countries</b>
            <p>Personal data that we collect or receive may be transferred to and/or processed by third parties that are located outside of the EU, some of which the EU and/or UK authorities may not consider having an adequate level of protection for Personal Information. We will only transfer Personal Information outside of the EU when we have ensured appropriate safeguards for such Personal Information through use of the standard contractual clauses or other approved mechanisms.</p>
          </li>
          <li>
            <b>Your Privacy Rights</b>
            <p>
              Subject to certain exemptions, you may have the right to:
              <ul className="pb-2">
                <li><b>Request access</b> to your Personal Information.</li>
                <li><b>Request correction</b> of your Personal Information.</li>
                <li><b>Request erasure</b>  of your Personal Information. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</li>
                <li><b>Object to processing</b> of your Personal Information.</li>
                <li><b>Request restriction of processing</b> of your Personal Information.</li>
                <li><b>Request the transfer</b> of your Personal Information to you or a third party.</li>
                <li><b>Withdraw consent</b> at any time where we are relying on consent to process your Personal Information.</li>
              </ul>
              For more information on how to exercise any of these rights, see the Contact Us section below. You may have a right to lodge a complaint with a supervisory authority or other regulatory agency if you believe that we have violated any of the rights concerning your Personal Information.  We encourage you to first reach out to us, so we have an opportunity to address your concerns directly before you do so.
            </p>
          </li>
        </ul>
      </p>
      <h2 className="has-text-black pb-2 is-size-4 has-text-weight-bold">Links to Third-Party Websites</h2>
      <p className="content pb-5">
        We are not responsible for the practices employed by any websites or services linked to or from the Site, including the information or content contained within them. We encourage you to investigate and ask questions before disclosing Personal Information to third parties, since any Personal Information disclosed will be subject to the applicable third party’s Privacy Notice.
      </p>
      <h2 className="has-text-black pb-2 is-size-4 has-text-weight-bold">Changes to this Privacy Notice</h2>
      <p className="content pb-5">
        We may modify or update this Privacy Notice from time to time, so please review it periodically. We may provide you with an updated Privacy Notice if material changes are made.  Unless otherwise indicated, any changes to this Privacy Notice will apply immediately upon posting to the Site.
      </p>
      <h2 className="has-text-black pb-2 is-size-4 has-text-weight-bold">Contact Us</h2>
      <p className="content pb-5">
        If you have any questions about our practices or this Privacy Notice, please contact us at <a href="mailto://privacy@diddirectory.com">privacy@diddirectory.com</a>.
      </p>



    </div>
  )
}

export default LegalPage
