import React from 'react'


const ClaimingTag = ({isUnclaimable, isUnclaimed, openUnclaimableModal, openClaimListingModal}) => {


  function fetchClaimTag() {
    if (isUnclaimable) {
      return <button
        onClick={openUnclaimableModal}
        className="button has-text-black-bis m-5 pr-2 pl-2 is-size-6 has-background-white-ter">
        Unclaimable
      </button>
    } else if (isUnclaimed) {
      return <button
        className="button has-text-black-bis m-5 pr-2 pl-2 is-size-6 has-background-white-ter"
        onClick={openClaimListingModal}
      >
        Unclaimed
      </button>
    } else {
      return <span className="has-text-white is-rounded has-background-primary m-5 pr-2 pl-2 ">Claimed</span>
    }
  }



  return (
    <div className="is-flex is-justify-content-center">
      {fetchClaimTag()}

    </div>
  )
}

export default ClaimingTag
