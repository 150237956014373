import React from 'react'
import EditableFieldProperty from "../../MethodListingPage/components/EditableFieldProperty";
import {mdiDeleteOutline, mdiUploadOutline } from "@mdi/js";
import {useNavigate} from 'react-router-dom'
import {deleteUserAccount, updateProfile} from "../../../services/user";
import Icon from '@mdi/react'
import ProfileAvatar from "./ProfileAvatar";
import {EXPRESS_SERVER} from "../../../env_config";
import {toast} from "react-toastify";
import ClaimedListingsCard from "./ClaimedListingsCard";
import AuthMechanismsCard from "./AuthMechanismsCard";
import {ConfirmationModalContext} from "../../../Contexts/ConfirmationModalContext";
import {AuthenticationContext} from "../../../Contexts/AuthenticationContext";

const EditableProfileDetails = ({profileId, userId, profileData, verifiedEmails, githubId, githubHandle, endEditSession, onUpdateSuccessful, refreshData, claimedListings}) => {

  let {openConfirmationModal} = React.useContext(ConfirmationModalContext)
  let inputFile = React.useRef(null)
  let navigate = useNavigate()
  let {refreshAuth} = React.useContext(AuthenticationContext)

  let [displayName, setDisplayName] = React.useState(profileData.name || "")
  let [emails, setEmails] = React.useState(verifiedEmails || [])

  let [updatedProfileFile, setUpdatedProfileFile] = React.useState(null)
  let [removeProfilePic, setRemoveProfilePic] = React.useState(false)
  let [profilePicUrl, setProfilePicUrl] = React.useState(profileData.profilePicFileName ? `${EXPRESS_SERVER}/profilePics/${profileId}/${profileData.profilePicFileName}` : null)
  let [organisation, setOrganisation] = React.useState(profileData.organisation || "")

  let [githubBound, setGithubBound] = React.useState(githubId != null)


  React.useEffect(() => {
    setEmails(verifiedEmails || [])
    setDisplayName(profileData.name)
    setGithubBound(githubId != null)
  }, [profileData,verifiedEmails,githubId])

  React.useEffect(() => {
    updatedProfileFile ?
      setProfilePicUrl(URL.createObjectURL(updatedProfileFile))
      : setProfilePicUrl(profileData.profilePicFileName ? `${EXPRESS_SERVER}/profilePics/${profileId}/${profileData.profilePicFileName}` : null)

  }, [updatedProfileFile, profileData.profilePicFileName, profileId])



  function cancelEditProfile() {
    endEditSession()
    setDisplayName(profileData.name || "")
    setOrganisation(profileData.organisation || "")
  }

  function submitProfileUpdate() {

    let updateProfileForm = new FormData()

    let updateProfileData = {
      "displayName": displayName,
      "organisation": organisation,
      "removeProfilePic": removeProfilePic,
      "verifiedEmails": emails,
      "githubBound": githubBound
    }

    updateProfileForm.append("updateProfileData", JSON.stringify(updateProfileData))
    updateProfileForm.append("profilePicFile", updatedProfileFile)

    updateProfile(profileId, updateProfileForm)
      .then(res => {
        onUpdateSuccessful(res.data);
        endEditSession();
      }).catch(error => {
        toast.error(error.response.data);
        setGithubBound(githubId !== null);
        setEmails(verifiedEmails || [])
    })
  }

  // TODO: Refactor this. Input for files should be uncontrolled (no value or onChange)
  function handleProfilePicInput(e) {
    const file = e.target.files[0];

    if (file.size < 2048) {
      toast.error("File size cannot exceed more than 2MB");
    }
    else {
      setUpdatedProfileFile(file)
      setRemoveProfilePic(false)
    }
  }

  function removeCurrentProfilePic() {
    setUpdatedProfileFile(null)
    setRemoveProfilePic(true)
    setProfilePicUrl(null)
  }

  function deleteProfile() {
    deleteUserAccount(profileId)
      .then(res => {
        refreshAuth()
        navigate("/")
      })
      .catch(e => {
        toast.error("Error deleting account")
      })
  }

  return <div className="is-flex is-flex-direction-column is-align-items-center is-relative" style={{top: "-90px"}}>
    <ProfileAvatar profilePicUrl={profilePicUrl} displayName={profileData.name} userId={profileId} />
    <div className="mt-3">
      {profilePicUrl ?
        <button className="button ml-4 mt-2" onClick={removeCurrentProfilePic}>
        <span className="icon-text">
                <span className="icon">
                  <Icon path={mdiDeleteOutline}
                        title="Upload Logo"
                        size={1}
                        horizontal
                        vertical
                        rotate={180}
                        color="black"
                  />
                </span>
                <span>Remove photo</span>
              </span>
        </button>
        :
        <button className="button ml-4 mt-2" onClick={() => inputFile.current.click()}>
        <span className="icon-text">
                <span className="icon">
                  <Icon path={mdiUploadOutline}
                        title="Upload Logo"
                        size={1}
                        horizontal
                        vertical
                        rotate={180}
                        color="black"
                  />
                </span>
                <span>Upload photo</span>
              </span>
        </button>
      }
      <input type='file' id='file' ref={inputFile} accept="image/jpeg, image/png" onChange={handleProfilePicInput} style={{display: 'none'}}/>
    </div>
    <div className="card">
      <EditableFieldProperty value={displayName} propertyName="DISPLAY NAME" onValueChange={setDisplayName}/>
      <EditableFieldProperty value={organisation} propertyName="ORGANIZATION" onValueChange={setOrganisation}/>

    </div>
    <AuthMechanismsCard emails={emails} setEmails={setEmails} refreshData={refreshData} githubBound={githubBound} githubHandle={githubHandle} githubId={githubId} setGithubBound={setGithubBound}/>
    {claimedListings && claimedListings.length > 0 && <ClaimedListingsCard claimedListings={claimedListings}/>}

    <div style={{width: "100%"}}>
      <span className="is-clickable is-size-6 is-text-black" onClick={() => {
        openConfirmationModal("Are you sure you want to delete your account?", deleteProfile)
      }
      }>Delete account</span>
      <span className="is-pulled-right">
        <button className="button has-background-white-bis mr-2 ml-2" onClick={cancelEditProfile}>Cancel</button>
        <button className="button is-primary mr-2 ml-2 pl-3 pr-3" onClick={submitProfileUpdate}>Save Changes</button>
      </span>
    </div>

  </div>
}


export default EditableProfileDetails
