import axios from "axios"
import {EXPRESS_SERVER} from "../env_config";



const axiosInstance = axios.create({
  baseURL: `${EXPRESS_SERVER}/api`,
  withCredentials: true
})


export default axiosInstance
