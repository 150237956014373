import axiosInstance from './axiosInstance'


export const searchListings = (searchFilter) => {

  let qs = null
  if (searchFilter) {
    qs = "search="+searchFilter
  }

  let request = '/listing';
  if (qs) {
    request += "?" + qs
  }

  return axiosInstance.get(request)

}

export const getListingById = (listingId) => {
  return axiosInstance.get("/listing/" + listingId)
}

export const getListingsByMethodName = (methodName) => {
  return axiosInstance.get('/listing/search/' + methodName)
}

export const sendClaimListingOtp = (listingId) => {
  return axiosInstance.post("/listing/claim/" + listingId + "/send/otp", {})
}

export const claimListing = (listingId) => {

  return axiosInstance.post("/listing/claim/" + listingId, {})
}

export const unclaimListing = (listingId) => {
  return axiosInstance.post("/listing/unclaim/" + listingId, {})
}

export const updateListingDisplayData = (listingId, updatedDisplayData) => {
  return axiosInstance.post("/listing/update/" + listingId, updatedDisplayData)
}

export const claimListingWithOtp = (listingId, otp) => {
  return axiosInstance.post("/listing/claim/" + listingId + '/otp', {otp: otp})
}

