import React from 'react'
import {mdiArrowLeft} from "@mdi/js";
import Icon from '@mdi/react'
import { useNavigate } from "react-router-dom";

const ListingHeader = ({methodName, canEdit, editMethod, backgroundColor, textColor}) => {
  let navigate = useNavigate();

  return (
    <div className="listing-header p-4 is-flex is-flex-direction-column is-relative" style={{backgroundColor: backgroundColor, zIndex: 1}}>
      <span className="is-flex is-clickable is-flex-grow-1" onClick={() => navigate(-1)}>
        <span className="icon is-left">
            <Icon path={mdiArrowLeft}
                  title="Back"
                  size={1}
                  horizontal
                  vertical
                  rotate={180}
                  color={textColor}
            />
        </span>
        <span className="is-size-6" style={{color: textColor}}>Back</span>
      </span>
      <div className="columns">
        <div className="column is-narrow is-flex is-align-items-flex-end pb-0" >
          <span className="has-text-weight-bold ml-4 is-size-2" style={{color: textColor}}>did:{methodName}</span>
      </div>

        <div className="column"/>
        <div className="column is-narrow is-flex is-align-items-flex-end is-relative" >
          {canEdit && <button className="button" onClick={editMethod}>Edit</button>}
        </div>
        <div className="column is-2"/>


      </div>




    </div>
  )
}

export default ListingHeader
