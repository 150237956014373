import React from 'react'
import {AuthenticationContext} from "../../../Contexts/AuthenticationContext";
import {getListingById} from "../../../services/listing";
import {toast} from "react-toastify";
import ListingHeader from "./ListingHeader";
import ListingLogo from "../../../components/ListingLogo";
import UnclaimedListingDetails from "./UnclaimedListingDetails";
import EditableListingDetails from "./EditableListingDetails";
import {mdiDeleteOutline, mdiUpload} from "@mdi/js";
import Icon from '@mdi/react'
import ClaimedListingDetails from "./ClaimedListingDetails";
import ClaimingTag from "./ClaimingTag";
import {getUserById} from "../../../services/user";
import UnclaimableModal from "./UnclaimableModal";
import ClaimListingModal from "./ClaimListingModal";
import ColorPicker from "./ColorPicker";
import {createLogoFileUrl} from "../../../helpers/helpers";

const debug = require('debug')('ListingPageContent');

const ListingPageContent = ({listingId, listing}) => {
  const inputFile = React.useRef(null);

  let [methodListing, setMethodListing] = React.useState(listing);
  let [claimableAccountId, setClaimableAccountId] = React.useState(null);
  let {userId} = React.useContext(AuthenticationContext);
  let [editMode, setEditMode] = React.useState(false);
  let [showUnclaimableModal, setShowUnclaimableModal] = React.useState(false);
  let [showClaimListingModal, setShowCLaimListingModal] = React.useState(false);
  let [authenticatedUser, setAuthenticatedUser] = React.useState(null);

  let [backgroundColor, setBackgroundColor] = React.useState(methodListing ? methodListing.displayData.backgroundColor || "#1A8572" : "#1A8572");
  let [textColor, setTextColor] = React.useState(methodListing ? methodListing.displayData.textColor || "#FFFFFF" : "#FFFFFF");
  let [logoFileUrl, setLogoFileUrl] = React.useState(null);
  let [logoFile, setLogoFile] = React.useState(null);
  let [deleteLogoFile, setDeleteLogoFile] = React.useState(false);


  React.useEffect(() => {
    if (!listing) {
      fetchListing()
    }
  }, [listingId])

  React.useEffect(() => {
    fetchListing()
    if (!userId) {
      setEditMode(false)
    }

  }, [userId])


  React.useEffect(() => {
    if (userId) {
      getUserById(userId)
        .then(res => {
          setAuthenticatedUser(res.data)
        })
    } else {
      setAuthenticatedUser(null)
    }

  }, [userId])


  React.useEffect(() => {

    if (methodListing) {
      setLogoFileUrl(methodListing.displayData.logoFileName ? createLogoFileUrl(methodListing._id, methodListing.displayData.logoFileName) : null);
      setBackgroundColor(methodListing.displayData.backgroundColor || "#1A8572");
      setTextColor(methodListing.displayData.textColor || "#FFFFFF" );
    } else {
      setLogoFileUrl(null);
    }
  }, [methodListing])

  function fetchListing() {
    getListingById(listingId)
      .then(res => {
        setMethodListing(res.data.listing);
        setClaimableAccountId(res.data.claimableById);
        debug("Listing", res.data)
      })
      .catch(err => {
        debug("Error fetching method listing with id " + listingId, err);
        toast.error(err.response.data)
      })
  }

  const handleLogoInput = (e) => {
    // handle validations
    const file = e.target.files[0];

    if (file.size < 2048) {
      toast.error("File size cannot exceed more than 2MB");
    }
    else {
      setLogoFile(file)
      setDeleteLogoFile(false)
      setLogoFileUrl(URL.createObjectURL(file))
    }

  };

  function closeEditSession() {
    setEditMode(false);
    setLogoFile(null);
    setDeleteLogoFile(false);
    // Revert colors back to before edit
    setLogoFileUrl(methodListing.displayData.logoFileName ? createLogoFileUrl(methodListing._id, methodListing.displayData.logoFileName) : null);
    setBackgroundColor(methodListing.displayData.backgroundColor || "#1A8572");
    setTextColor(methodListing.displayData.textColor || "#FFFFFF" )
  }

  function onListingUpdated(updatedListing) {
    setMethodListing(updatedListing)
  }

  function onColorSelection(backgroundColor, textColor){
    setBackgroundColor(backgroundColor)
    setTextColor(textColor)
  }

  return methodListing ? (<div className="listing-details is-relative">
    <ListingHeader
      methodName={methodListing.registryEntry.name}
      editMethod={() => setEditMode(true)}
      canEdit={!editMode && methodListing.listingController && methodListing.listingController === userId}
      editMode={editMode}
      backgroundColor={backgroundColor}
      textColor={textColor}
    />
    <div className="columns">
      <div className="column is-narrow is-relative" style={{zIndex: 2}}>
        <div className="logo-column is-flex is-flex-direction-column is-align-items-center">
          <ListingLogo methodName={methodListing.registryEntry.name} logoFileUrl={logoFileUrl} backgroundColor={backgroundColor} textColor={textColor}/>
          {editMode && <>

            <ColorPicker currentBackground={backgroundColor} onSelectColor={onColorSelection}/>
            {logoFileUrl ?
              <button className="button mt-2" onClick={() => {
                setLogoFileUrl(null)
                setLogoFile(null)
                setDeleteLogoFile(true)
              }}>
                <span className="icon-text">
                <span className="icon">
                  <Icon path={mdiDeleteOutline}
                        title="Remove logo"
                        size={1}
                        horizontal
                        vertical
                        rotate={180}
                        color="black"
                  />
                </span>
                <span>Remove Logo</span>
                </span>
              </button>
              :
              <button className="button mt-2" onClick={() => inputFile.current.click()}>
               <span className="icon-text">
                <span className="icon">
                  <Icon path={mdiUpload}
                        title="Upload Logo"
                        size={1}
                        horizontal
                        vertical
                        rotate={180}
                        color="black"
                  />
                </span>
                <span>Upload Logo</span>
              </span>
              </button>

            }
            <input type='file' id='file' ref={inputFile} accept="image/jpeg, image/png" onChange={handleLogoInput} style={{display: 'none'}}/>
          </>}

          <ClaimingTag
            isUnclaimable={!methodListing.registryEntry.contactEmail}
            isUnclaimed={!methodListing.listingController}
            openUnclaimableModal={() => setShowUnclaimableModal(true)}
            openClaimListingModal={() => setShowCLaimListingModal(true)}
          />

        </div>


      </div>

      <div className="column">
        {editMode ?
          <EditableListingDetails
            listingId={listingId}
            closeEditSession={closeEditSession}
            onListingUpdated={onListingUpdated}
            listingData={methodListing.displayData}
            uploadedLogo={logoFile}
            backgroundColor={backgroundColor}
            textColor={textColor}
            deleteLogoFile={deleteLogoFile}
          />
          :
          <>
          {methodListing.listingController ?
            <ClaimedListingDetails listingData={methodListing.displayData} w3cRegEntry={methodListing.registryEntry} controllerId={methodListing.listingController}/>
            :
            <UnclaimedListingDetails listingData={methodListing.registryEntry} />}
          </>

        }
      </div>

      <div className="column is-2"/>

    </div>

    {showUnclaimableModal && <UnclaimableModal closeModal={() => setShowUnclaimableModal(false)} methodName={methodListing.registryEntry.name}/>}
    {showClaimListingModal && <ClaimListingModal
      claimableAccountId={claimableAccountId}
      closeModal={() => setShowCLaimListingModal(false)}
      listingId={listingId}
      methodName={methodListing.registryEntry.name}
      contactEmail={methodListing.registryEntry.contactEmail}
      onListingClaimed={() => {
        fetchListing()
        setEditMode(true)
      }}
      authenticatedUser={authenticatedUser}/>}
  </div>) : <div/>
}

export default ListingPageContent
