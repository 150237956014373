import React from 'react'

import {Link} from 'react-router-dom'
import {createLogoFileUrl} from "../../../helpers/helpers";

const ListingTag = ({listingId, listingData}) => {
  let [logoFileUrl] = React.useState(listingData.logoFileName ? createLogoFileUrl(listingId, listingData.logoFileName) : null);


  return <span className="listing-tag">
    <Link to={'/' + listingData.name}>
      <span className={logoFileUrl ? "listing logo" : "listing"}>
        {logoFileUrl ? <img alt="DID Method Listing Logo" src={logoFileUrl}/> :
          <span className="acronym">{listingData.name.substring(0, 3)}</span>}
      </span>
      <span className="has-text-grey-dark">{listingData.name}</span>
    </Link>
  </span>
}

export default ListingTag
