import React from 'react'
import W3CRegEntryModal from "../components/modals/W3CRegEntryModal";

export const W3CModalContext = React.createContext({})

const W3CModalManager = ({children}) => {

  let [w3cRegEntry, setW3CRegEntry] = React.useState(null)

  let openW3CModal = function(w3cRegEntry) {
    setW3CRegEntry(w3cRegEntry)
  }

  let closeW3CModal = function () {
    setW3CRegEntry(null)
  }


  return (
    <W3CModalContext.Provider value={{openW3CModal}}>
      {children}
      {w3cRegEntry && <W3CRegEntryModal regEntryData={w3cRegEntry} closeModal={closeW3CModal}/>}
    </W3CModalContext.Provider>
  )

}

export default W3CModalManager
