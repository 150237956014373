import React from 'react'
import SearchListings from "./components/SearchListings";

const Index = () => {
  return (
    <div>
      <SearchListings/>
    </div>
  )
}

export default Index
