import React from 'react'
import EmailVerificationModal from '../components/modals/EmailVerificationModal'

export const EmailVerificationContext = React.createContext({});

// TODO: is handling the modal in a context like this really the best way?
const EmailVerification = ({children}) => {
  const [email, setEmail] =  React.useState(null);
  const [submitOtpApi, setSubmitOtpApi] = React.useState(null);
  const [submitEmailApi, setSubmitEmailApi] = React.useState(null);
  const [onOtpSuccess, setOnOtpSuccess] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);

  function openEmailVerificationModal(email, submitOtpApi, submitEmailApi, onSuccess) {

    setSubmitOtpApi(() => submitOtpApi);
    setSubmitEmailApi(() => submitEmailApi);
    setOnOtpSuccess(() => onSuccess);
    setEmail(email);
    setShowModal(true);
  }

  function closeModal() {
    setEmail(null);
    setSubmitOtpApi(null);
    setSubmitEmailApi(null);
    setShowModal(false);
  }

  return (
    <EmailVerificationContext.Provider value={{email, openEmailVerificationModal, closeModal}}>
      {children}
      {showModal && <EmailVerificationModal closeModal={closeModal} email={email} submitEmailApi={submitEmailApi} submitOtpApi={submitOtpApi} onOtpSuccess={onOtpSuccess}/>}
    </EmailVerificationContext.Provider>
  )
}

export default EmailVerification
