import React from 'react';

const AboutPage = () => {

  return <div className="container mt-6 pl-6 pr-6">
    <h1 className="title has-text-primary">The DID Directory</h1>
    <p className="pb-3 pt-3">
      The <strong><a href="https://diddirectory.com">DID Directory</a></strong> is a public directory of <strong>DID
      methods</strong>, provided by <a href="http://legreq.com">Legendary Requirements</a>, long time advocates for
      decentralized identity and its emerging technologies, such as the <a href="http://w3.org/TR/did-core">Decentralized
      Identifiers</a> from the <a href="https://w3.org">World Wide Web Consortium</a>.
    </p>
    <p className="pb-3 pt-3">
      <strong>Decentralized Identifiers (DIDs)</strong> enable identity-based services without dependence on a trusted
      third party. Instead of requiring centralized identity verification services, like Facebook, Google or the
      Department of Motor Vehicles, <strong>DIDs</strong> can be created by anyone, anywhere, and be used for any
      purpose.
    </p>
    <p className="pb-3 pt-3">
      <strong>DID methods</strong> are the magic ingredient that gives DIDs their flexibility. Before creating any
      specific <strong>DID</strong>, you first choose a <strong>DID</strong> method, which determines how you perform
      the create, read, update, and deactivate operations on a <strong>DID</strong> of that method.
    </p>
    <p className="pb-3 pt-3">
      Once created, each <strong>DID</strong> includes the name of its method in the identifier itself, so that when you
      use the <strong>DID</strong>, others know how to retrieve the associated <strong>DID Document</strong> that
      contains the cryptographic material for secure interactions.
    </p>
    <p className="pb-3 pt-3">
      Different <strong>DID methods</strong> use different underlying mechanisms with different performance, security,
      and privacy tradeoffs.
    </p>
    <p  className="pb-3 pt-3">
    The <strong><a href="https://diddirectory.com">DID Directory</a></strong> provides an easy to use directory of all
    current <strong> DID methods</strong>. It allows <strong> DID method</strong> controllers to claim their official
    listing and augment their entry with short and long descriptions, a logo, and other useful data. In turn, the
    directory allows interested parties to easily search and explore current DID methods to better understand what
    methods are currently available and how they differentiate from each other.
  </p>
    <p className="pb-3 pt-3">
    In short, the <strong><a href="https://diddirectory.com">DID Directory</a></strong> is the place to go to learn more
    about <strong>DID methods</strong>.
  </p>
  </div>
}

export default AboutPage
