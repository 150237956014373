import React from 'react'
import ProfileAvatar from "./ProfileAvatar";
import {EXPRESS_SERVER} from "../../../env_config";
import ClaimedListingsCard from "./ClaimedListingsCard";
import {mdiEmailOutline, mdiGithub} from "@mdi/js";
import Icon from '@mdi/react'

const ProfileDetails = ({profileData, verifiedEmails, isController, setEditMode, claimedListings, userId, githubHandle}) => {


  let [profilePicUrl] = React.useState(profileData.profilePicFileName ? `${EXPRESS_SERVER}/profilePics/${userId}/${profileData.profilePicFileName}` : null)

  return (
    <div className="is-flex is-flex-direction-column is-align-items-center is-relative" style={{top: "-90px"}}>

      <ProfileAvatar profilePicUrl={profilePicUrl} displayName={profileData.name} userId={userId}/>

      <div className="card">
        <span
          className={profileData.name ? "m-4 title has-text-primary" : "m-4 title has-text-grey-light"}>{profileData.name ? profileData.name : "No Display Name"}</span>
        <span className="m-4 is-flex is-flex-direction-column">
          <span className="is-size-6 has-text-black">ORGANIZATION</span>
          <span className={profileData.organisation ? "is-size-6 has-text-grey-dark" : "is-size-6 has-text-grey-light"}>
            {profileData.organisation ? profileData.organisation : "None Submitted"}
            </span>
        </span>
        {isController && <span className="m-4 is-flex is-flex-direction-column">
          <span className="is-size-6 has-text-black">VERIFIED AUTHENTICATION MECHANISMS</span>
          <div className="mt-2">
              {verifiedEmails.map(email => {
                return <div key={email} className="icon-text mb-1">
                <span className="icon">
                      <Icon path={mdiEmailOutline}
                            title="Github"
                            size={1}
                            horizontal
                            vertical
                            rotate={180}
                            color="black"
                      />
                    </span>
                  <a key={email} className="is-size-6" href={`mailto://${email}`}>{email}</a>
              </div>
              })}
            {githubHandle && <span className="icon-text">
            <span className="icon">
              <Icon path={mdiGithub}
                    title="Github"
                    size={1}
                    horizontal
                    vertical
                    rotate={180}
                    color="black"
              />
            </span>
            <a id={githubHandle} target="_blank" className="is-size-6" href={`https://github.com/${githubHandle}`}>{githubHandle}</a>
          </span>}
          </div>

        </span>}
      </div>
      {claimedListings && claimedListings.length > 0 && <ClaimedListingsCard claimedListings={claimedListings}/>}
      {isController &&
      <button className="button is-primary is-align-self-center" onClick={() => setEditMode(true)}>Edit</button>}
    </div>

  )
}

export default ProfileDetails
