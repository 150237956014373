import axiosInstance from './axiosInstance'


export const checkIsAuthenticated = () => {
  return axiosInstance.get("/auth/status")
}

export const emailLogin = (email) => {
  return axiosInstance.post("/auth/email/login", { email:email})
}


export const emailOtpAuth = (email, otp) => {
  return axiosInstance.post("/auth/email/otp", {email, otp})
}

export const emailRegister = (email) => {
  return axiosInstance.post("/auth/email/register", { email:email})
}

export const authLogout = () => {
  return axiosInstance.post("/auth/logout")
}
